import React from "react";

const Tooltip = ({ text, position, children }) => {
  const getPositionStyles = () => {
    switch (position) {
      case "top":
        return "left-1/2 transform -translate-x-1/2 -top-2 -translate-y-[100%]";
      case "bottom":
        return "left-1/2 transform -translate-x-1/2 -bottom-2 translate-y-[100%]";
      case "left":
        return "top-1/2 transform -translate-y-1/2 -left-2 -translate-x-[100%]";
      case "right":
        return "top-1/2 transform -translate-y-1/2 -right-2 translate-x-[100%]";
      default:
        return "";
    }
  };

  return (
    <div className="relative inline-block group">
      {children}
      <div
        className={`hidden group-hover:block max-w-md w-max absolute z-10 bg-gray-700 text-white text-sm p-2 rounded-md mr-12 ${getPositionStyles()}`}
      >
        {text}
        <div
          className={`w-2 h-2 bg-gray-700 absolute ${
            position === "top"
              ? "-bottom-[0.25rem] left-1/2 -translate-x-1/2 rotate-45 rounded-br-sm"
              : ""
          }${
            position === "bottom"
              ? "-top-[0.25rem] left-1/2 -translate-x-1/2 -rotate-45 rounded-tr-sm"
              : ""
          }${
            position === "left"
              ? "top-1/2 -right-[0.25rem] -translate-y-1/2 rotate-45 rounded-tr-sm"
              : ""
          }${
            position === "right"
              ? "top-1/2 -left-[0.25rem] -translate-y-1/2 -rotate-45 rounded-tl-sm"
              : ""
          }`}
        ></div>
      </div>
    </div>
  );
};

export default Tooltip;
