import { Link } from "react-router-dom";
import MyAmaStatsItem from "./MyAmaStatsItem";

export default function AmaItem(props) {
  return (
    <li className="flex flex-col mm:flex-row border bg-white p-4 xm:p-6 rounded-3xl mb-6">
      <div className="flex mm:mr-4">
        <div>
          <Link to={`/ama/${props.id}/`} className="text-lg font-medium">
            {props.title ? props.title : "No title"}
          </Link>
          <p className="text-xs text-slate-400">
            {new Date(props.dateCreated).toLocaleDateString("en-us", {
              weekday: "long",
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
            })}
          </p>
          <p className="mt-2 max-w-[80em] break-words text-sm mm:text-md sm:text-base">
            {props.description}
          </p>
        </div>
      </div>
      <div className="flex flex-row mm:flex-col items-baseline mt-4 mm:mt-0 mm:ml-auto">
        <MyAmaStatsItem
          twStyle="w-full sxm:w-auto mm:w-full mm:mb-4 sxm:px-6 xm:px-10 mm:px-0 mr-4 mm:mr-0"
          count={props.numberOfAnsweredQuestions}
          text={"Answers"}
        />
        <Link
          to={"/ama/" + props.id}
          className="bg-purple-500 hover:bg-purple-700 rounded-full flex items-center p-3 px-6 ml-auto mm:ml-none min-w-max mt-auto h-auto text-white text-center font-medium"
        >
          Show AMA
        </Link>
      </div>
    </li>
  );
}
