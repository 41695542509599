export default function LoadingSpinner() {
  return (
    <div
      className="w-10 h-10 rounded-full animate-spin
    border-4 border-solid border-purple-500 border-t-transparent"
    >
      <span className="sr-only">Loading...</span>
    </div>
  );
}
