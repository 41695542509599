import Question from "./Question";
import MyAmaStatsItem from "./MyAmaStatsItem";
import { Link, useNavigate } from "react-router-dom";
import Avvvatars from "avvvatars-react";

export default function FeaturedAMA({ ama, onHandleVote, votes }) {
  const question = ama.questionanswer_set.reduce((prev, current) =>
    prev.votes > current.votes ? prev : current
  );
  const navigate = useNavigate();

  const statsItemClass = "w-full sm:w-auto px-2 sm:px-4";
  return (
    <div className="rounded-3xl border bg-white p-6 space-y-6 w-full h-full flex flex-col">
      <div className="flex items-center">
        <Avvvatars
          border={false}
          // eslint-disable-next-line react/style-prop-object
          style="shape"
          size={46}
          value={ama.username}
          className="items-center justify-center"
        />
        <p className="ml-2 text-xl font-medium">{ama.username}</p>
      </div>
      <div className="flex-1 sm:col-span-2">
        <h2 className="font-semibold sm:text-lg mb-2">Description</h2>
        <p className="text-sm sm:text-md whitespace-pre-wrap">
          {ama.description.length > 200
            ? ama.description.substring(0, 200) + "..."
            : ama.description}
        </p>
      </div>
      <div>
        <h2 className="font-semibold sm:text-lg mb-2">Top question</h2>
        <Question
          index={0}
          id={question.uuid}
          question={question.question}
          answer={question.answer}
          votes={question.votes}
          voted={votes[question.uuid] ?? question.voted}
          dateAnswered={question.answered_at}
          dateAsked={question.asked_at}
          onClickVote={(id, type) => {
            onHandleVote(id, ama.uuid, type, question.voted);
          }}
          onClickOption={(e) => {}}
          options={null}
          onToggle={() => {
            navigate("/question/" + question.uuid);
          }}
          active={false}
        />
      </div>
      <div>
        <div className="flex flex-col sm:flex-row items-center">
          <div className="flex justify-between mm:justify-start w-full sm:w-auto">
            <MyAmaStatsItem
              twStyle={statsItemClass}
              count={ama.questionanswer_set.length}
              text={"Questions"}
              dataTestId="stats-div-questions"
            />
            <div className="mr-3 xm:mr-4" />
            <MyAmaStatsItem
              twStyle={statsItemClass}
              count={ama.questionanswer_set.filter((qa) => qa.answer).length}
              text={"Answered"}
              dataTestId="stats-div-answered"
            />
            <div className="mr-0 tiny:mr-3 xm:mr-4" />
            <MyAmaStatsItem
              twStyle={"hidden sxm:flex " + statsItemClass}
              count={ama.questionanswer_set.filter((qa) => !qa.answer).length}
              text={"Unanswered"}
              dataTestId="stats-div-unanswered"
            />
          </div>
          <Link
            to={"/ama/" + ama.uuid}
            className="bg-purple-500 hover:bg-purple-700 rounded-full h-full sm:self-end p-3 px-6 w-full sm:w-fit ml-none mt-6 sm:mt-auto md:mt-0 sm:ml-auto text-white text-center font-medium"
          >
            View AMA
          </Link>
        </div>
      </div>
    </div>
  );
}
