import { useState } from "react";
import Title from "./Title";
import AmaItem from "./AmaItem";
import Dropdown from "./Dropdown";

export default function Amas(props) {
  const [amas, setAmas] = useState(props.amas);

  const onClickSort = (sortBy) => {
    setAmas(() => {
      switch (sortBy) {
        case 0:
        case 1:
          return sortByDate(sortBy);
        default:
          return [...amas];
      }
    });
  };

  const sortByDate = (type) => {
    const newList = amas.map((obj) => {
      return { ...obj, created_at: new Date(obj.created_at) };
    });

    return newList.sort((a, b) =>
      type === 0 ? b.created_at - a.created_at : a.created_at - b.created_at
    );
  };

  return (
    <div className="flex flex-1 min-h-screen content-center flex-col pt-36 md:pt-56 2xl:pt-72 pb-20 center-content self-center max-w-4xl px-6 md:px-10 w-full">
      <div className="flex flex-col">
        <div className="flex mb-8">
          <Title title={props.title} />
        </div>
        <div className="mb-4">
          <Dropdown
            sortByList={[
              { name: "Newest", id: 0 },
              { name: "Oldest", id: 1 },
            ]}
            onClickSort={onClickSort}
          />
        </div>
      </div>
      <ul>
        {!amas.length && (
          <li>
            <p>You have no AMAs yet</p>
          </li>
        )}
        {amas.map((ama) => (
          <AmaItem
            key={ama.uuid}
            title={ama.title}
            description={ama.description}
            dateCreated={ama.created_at}
            numberOfAnsweredQuestions={ama.num_of_answered_questions}
            id={ama.uuid}
          />
        ))}
      </ul>
    </div>
  );
}
