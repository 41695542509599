import VoteIcon from "./VoteIcon";

export default function Vote(props) {
  return (
    <div className="flex flex-row">
      <button
        id="upvote"
        aria-label="Upvote"
        onClick={() => props.onClickVote(props.id, 1)}
        className="flex items-center justify-center bg-white p-2 rounded-l-lg border group"
      >
        <VoteIcon
          styleFill={`${
            props.voted === 1 ? "fill-green-400" : "group-hover:fill-green-400"
          }`}
          styleBorder={`${
            props.voted === 1 ? "fill-green-500" : "group-hover:fill-green-500"
          }`}
          className="rotate-180 h-3"
        />
      </button>
      <div
        id={"vote-" + props.index}
        className="bg-white px-2 py-2 border-y text-center min-w-[2.2rem]"
      >
        {props.votes}
      </div>
      <button
        id="downvote"
        aria-label="Downvote"
        onClick={() => props.onClickVote(props.id, -1)}
        className="flex items-center justify-center bg-white p-2 rounded-r-lg border group"
      >
        <VoteIcon
          styleFill={`${
            props.voted === -1 ? "fill-red-400" : "group-hover:fill-red-400"
          }`}
          styleBorder={`${
            props.voted === -1 ? "fill-red-500" : "group-hover:fill-red-500"
          }`}
          className="h-3"
        />
      </button>
    </div>
  );
}
