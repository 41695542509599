import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { setNewPasswordFields } from "../constants/formFields";
import FormAction from "./FormAction";
import Input from "./Input";
import * as authApi from "../api/authApi";
import { useAuth } from "../contexts/AuthContext";
import { useErrorToast } from "../hooks/useErrorToast";

const fields = setNewPasswordFields;
let fieldsState = {};
fields.forEach((field) => (fieldsState[field.id] = ""));

export default function SetNewPassword(props) {
  const { csrf } = useAuth();
  const [formState, setFormState] = useState(fieldsState);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const { uidb64, token } = useParams();

  const showErrorToast = useErrorToast();

  const handleChange = (e) => {
    setFormState({ ...formState, [e.target.id]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    resetPassword();
  };

  const resetPassword = () => {
    setIsLoading(true);
    authApi
      .setNewPassword(formState, uidb64, token, csrf)
      .then(() => {
        props.setSubmitted(true);
        setErrors({});
      })
      .catch((error) => {
        if (error.response.status >= 400 && error.response.status < 500) {
          setErrors(error.response.data);
        } else {
          showErrorToast("Something went wrong, please try again!");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="-space-y-px">
          {fields.map((field) => (
            <Input
              key={field.id}
              handleChange={handleChange}
              value={formState[field.id]}
              labelText={field.labelText}
              labelFor={field.labelFor}
              id={field.id}
              name={field.name}
              type={field.type}
              isRequired={field.isRequired}
              placeholder={field.placeholder}
              error={errors[field.id]}
              autofocus={field.autofocus}
              autoComplete={field.autoComplete}
              isToggle={field.isToggle}
            />
          ))}
        </div>
        {errors.non_field_errors && (
          <div className="text-red-500 flex flex-col self-center items-center">
            <p>{errors.non_field_errors}</p>
          </div>
        )}
        <FormAction
          type="submit"
          text={props.submitted ? "New password set" : "Set password"}
          isLoading={isLoading}
          loadingText="Setting password"
          disabled={props.submitted}
          className={props.submitted && "bg-green-500"}
        />
      </form>

      <p className="text-center text-sm text-gray-600 mt-5">
        <Link
          to="/login"
          className="font-medium text-purple-600 hover:text-purple-500"
        >
          Back to login
        </Link>
      </p>
    </>
  );
}
