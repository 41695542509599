import api from "./axios";

export const submitContactForm = (inputs, csrf) => {
  return api.post(
    "/api/contact/",
    {
      email: inputs.email,
      subject: inputs.subject,
      message: inputs.message,
      telnumber: inputs.telnumber,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrf,
      },
      withCredentials: true,
    }
  );
};

export const reportUserOrAMA = (report, csrf) => {
  return api.post(
    "/ama/report/",
    {
      username: report.username,
      ama_uuid: report.ama_uuid,
      reason: `${
        report.specific &&
        "Reported " + report.specific + ", for following reason: "
      }${report.reason}`,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrf,
      },
      withCredentials: true,
    }
  );
};

export const reportQuestionAnswer = (report, csrf) => {
  return api.post(
    "/ama/question/report/",
    {
      question_answer_uuid: report.uuid,
      reason: `Reported ${report.selection}, for following reason: ${report.reason}`,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrf,
      },
      withCredentials: true,
    }
  );
};
