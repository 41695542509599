import { ReactComponent as DownloadStickerIcon } from "./../assets/download-sticker-icon.svg";

const faqQuestions = [
  {
    id: 0,
    question: "What is askm.ee?",
    answer: (
      <>
        askm.ee is a platform for users to create and participate in Ask Me
        Anything sessions, also called AMAs.
        <br />
        <br /> An AMA is an online event where an individual, who could be an
        influencer, an expert, a celebrity, or someone with an interesting story
        or perspective, invites others to ask them any question they wish to
        know the answer to.
        <br />
        <br />
        With askm.ee, we've built an easy-to-use platform that provides users
        with a modern and simple interface.
      </>
    ),
    isExpanded: false,
  },
  {
    id: 1,
    question: "What is an AMA?",
    answer: (
      <>
        AMA stands for "Ask Me Anything". It's a type of online interview or Q&A
        session where a person, usually a celebrity, expert, or public figure,
        invites questions from the public on a social media platform, forum or
        live stream, and answers them.
        <br />
        <br />
        The questions can cover any topic, from personal life and career to
        politics, science, technology, or anything else that the audience might
        be interested in.
        <br />
        <br />
        AMAs can be educational, informative, and entertaining, and they provide
        a unique opportunity for the public to interact with their favorite
        influencers, celebrities or experts directly.
        <br />
        <br />
        It's a great way to directly connect with fans, share insights, and
        discuss topics of interest.
      </>
    ),
    isExpanded: false,
  },
  {
    id: 2,
    question: "How can I create an AMA?",
    answer: (
      <>
        To create an AMA on our platform, you need to first create an account.
        Once you have successfully logged in, you will be directed to a page
        named "My AMAs." Here, you will find the option to create an AMA by
        simply clicking the "Create AMA" button. Upon doing so, you will be
        prompted to enter a title for your AMA, and you can optionally provide a
        brief description of the AMA. Once you are done, hit the "Create AMA"
        button, and your AMA will be created.
        <br />
        <br />
        Now that your AMA is up and running, you can share the URL for the
        session with your audience. To do this, click on "Answer Questions" next
        to your created AMA. You will be redirected to a page where you will
        find the URL for your AMA, copy this URL and share it with your
        followers or participants. Alternatively, you can share a link to your
        profile, which looks like this: askm.ee/your-username. Here,
        "your-username" is your unique username on the platform, and it allows
        users to either see all of your AMAs or one specific AMA.
        <br />
        <br />
        So go ahead, create an AMA, and engage with your audience like never
        before!
      </>
    ),
    isExpanded: false,
  },
  {
    id: 3,
    question: "Why can I create more than one AMA?",
    answer: (
      <>
        We understand that you may have varied interests and want to offer your
        audience the opportunity to interact with you on different topics.
        That's why we allow you to create multiple AMAs on our platform.
        <br />
        <br />
        Creating multiple AMAs can help you keep the conversations focused and
        more engaging. By dedicating specific sessions to different topics, you
        can have more in-depth discussions and cater to a wider audience.
        <br />
        <br />
        Furthermore, we have made it easy for you to manage your AMAs. You can
        customize your profile to display either a specific AMA or a list of all
        the AMAs you have created. This gives your audience the flexibility to
        choose which AMA they would like to participate in, making it easier for
        them to access the content they are interested in.
        <br />
        <br />
        It's your choice! So go ahead, create as many AMAs as you like, and
        engage with your audience like never before!
      </>
    ),
    isExpanded: false,
  },
  {
    id: 4,
    question: "How can I answer questions?",
    answer: (
      <>
        Answering questions on our platform is as easy as it gets! Simply
        navigate to the "My AMAs" tab via the header, and then select "Answer
        Questions" next to the AMA you want to engage with.
        <br />
        <br />
        Once you have selected the appropriate AMA, you will see a list of all
        the questions that have been asked. To answer a question, click on the
        "Answer Question" button next to it. This will open a window where you
        can enter your response.
        <br />
        <br />
        We encourage our users to take their time and craft thoughtful answers
        that provide value to their audience. When you are done writing your
        response, simply click on the "Answer" button to submit it. It's that
        easy!
        <br />
        <br />
        We believe that answering questions is a crucial component of the AMA
        experience, and we have made it as simple and user-friendly as possible.
        So go ahead and answer some questions!
      </>
    ),
    isExpanded: false,
  },
  {
    id: 5,
    question: "Where can I find the link to my AMA?",
    answer: (
      <>
        To access the link, simply log in to your account and navigate to the
        "My AMAs" page. Next, click on the "Answer Questions" button next to the
        AMA for which you want to find the link. Once you are on the AMA page,
        you will see the link displayed prominently at the top of the page. You
        can copy and share this link with your followers to invite them to join
        your AMA.
        <br />
        <br />
        Additionally, you can share a link to your profile, your profile link is
        just askm.ee/your-username, where "your-username" is replaced with your
        actual username. We let you decide what will be shown on your profile,
        you can choose to display a specific AMA, or you can opt to showcase a
        list of all your AMAs. You can change this setting in your profile
        settings.
      </>
    ),
    isExpanded: false,
  },
  {
    id: 6,
    question: "How can I delete my account?",
    answer: (
      <>
        To delete your account, log in to your account and navigate to the
        settings. Under the "Profile Info" tab, you will find a "Delete Account"
        button. Click on this button, thereafter you will be prompted to enter
        your password for security reasons.
        <br />
        <br />
        We take the security of our users' accounts very seriously, and
        therefore, we require you to enter your password before deleting your
        account. This ensures that only you, the authorized user, can delete
        your account and that no one else can do so without your permission.
        <br />
        <br />
        Please note that once you delete your account, all your data, including
        your AMAs and other information, will be permanently deleted and cannot
        be recovered.
        <br />
        <br />
        We hope that our platform has been a valuable resource for you, and if
        you ever decide to return, we will be here to welcome you back with open
        arms.
      </>
    ),
    isExpanded: false,
  },
  {
    id: 7,
    question: "How can I add my AMA to instagram?",
    answer: (
      <>
        You have two options, you can either link to a specific AMA or to your
        askm.ee profile. (Note that your askm.ee profile can either contain a
        specific AMA or all of you AMAs.)
        <br />
        <br />
        To do this, navigate to your Instagram profile, look for the button
        "Edit profile" and click on it, then on "Add link". Now, here you can
        either add the link to your profile, which looks something like this:
        askm.ee/your-username, where "your-username" is your unique username. Or
        you can add the link of a specific AMA to your bio.
        <br />
        <br />
        If you already use a multi-link tool like Linktree, you can just add
        your askm.ee link to that.
      </>
    ),
    isExpanded: false,
  },
  {
    id: 8,
    question:
      "How can I download questions as images to use them as Instagram stickers or similar?",
    answer: (
      <>
        <p>
          To download questions as images for use as Instagram stickers or
          similar graphics, please follow these steps:
        </p>
        <br />
        <ul className="space-y-4">
          <li>
            <b>1. Go to "My AMA's":</b>
            <br />
            Log in to your account and navigate to the "My AMA's" section.
          </li>

          <li>
            <b>2. Select "Answer Questions":</b>
            <br />
            On the "My AMA's" page, choose the "Answer Questions" option, next
            to the AMA you want to download a question from.
          </li>

          <li>
            <b>3. Download the question:</b>
            <br />
            Look for the blue button{" "}
            <DownloadStickerIcon className="inline w-6 h-6 p-1 pr-[0.35rem] bg-blue-500 text-white rounded-md" />{" "}
            with a sticker icon. This button is typically located next to or
            beneath the question you wish to use. Click on it.
          </li>
        </ul>
        <br />
        <p>
          That's it! You've successfully downloaded the question as an image.
          You can use it as an Instagram sticker, share it on social media, or
          employ it for various creative purposes to engage with your audience.
          Enjoy using this feature to enhance your interactions!
        </p>
      </>
    ),
  },
  {
    id: 9,
    question:
      "How do I use the downloaded image as a sticker in my Instagram story?",
    answer: (
      <>
        <p>
          Using the downloaded image as a sticker in your Instagram story is
          easy! Here are the steps:
        </p>
        <br />
        <ul className="space-y-4">
          <li>
            <b>1. Create a Story:</b>
            <br />
            Tap the plus icon at the bottom of your Instagram screen or swipe
            right anywhere in your Feed. Then, select "Story" at the bottom.
            <br />
          </li>
          <li>
            <b>2. Go to the sticker gallery:</b>
            <br />
            Press the sticker icon at the top (it looks like a face inside a
            sticker) to access the sticker gallery. In the gallery, search for
            the icon that resembles two rectangles with a plus sign inside.
          </li>
          <li>
            <b>3. Select the downloaded image:</b>
            <br />
            Browse through your photo gallery and choose the image you
            downloaded as a question sticker.
          </li>
          <li>
            <b>4. Customize as desired:</b>
            <br />
            Once the question image/sticker is added to your story, you can
            pinch, zoom, or rotate it to adjust its size and position to fit
            your story layout.
          </li>
        </ul>
        <br />
        That's it! You've successfully added the downloaded image as a sticker
        to your Instagram story!
      </>
    ),
  },
];

export { faqQuestions };
