import { useState } from "react";
import MyAmaItem from "../components/MyAmaItem";
import Title from "../components/Title";
import { useAuth } from "../contexts/AuthContext";
import { useEffect } from "react";
import Footer from "../components/Footer";
import DeleteModal from "../components/DeleteModal";
import EditCreateModal from "../components/EditCreateModal";
import LoadingSpinner from "../components/LoadingSpinner";
import { ToastContainer } from "react-toastify";
import { useErrorToast } from "../hooks/useErrorToast";
import Dropdown from "../components/Dropdown";
import * as userApi from "../api/userApi";
import * as authApi from "../api/authApi";
import { useLocation } from "react-router-dom";

export default function MyAmasPage() {
  const { state } = useLocation();
  const { csrf, getSession } = useAuth();

  const showErrorToast = useErrorToast();

  const [showModal, setShowModal] = useState({
    edit: false,
    create: state?.create || false,
    delete: false,
  });
  const [amas, setAmas] = useState([]);
  const [newAma, setNewAma] = useState({
    title: "",
    description: "",
  });
  const [amaSelection, setAmaSelection] = useState(null);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState({
    site: true,
    modal: false,
  });

  const handleDescriptionChange = (e) => {
    setNewAma({
      ...newAma,
      description: e.target.value,
    });
  };

  const handleTitleChange = (e) => {
    setNewAma({
      ...newAma,
      title: e.target.value,
    });
  };

  const createAma = (e) => {
    e.preventDefault();

    setIsLoading({ ...isLoading, modal: true });

    userApi
      .createAma(newAma.title, newAma.description, csrf)
      .then((response) => {
        const data = response.data;
        const newAma = {
          uuid: data.uuid,
          title: data.title,
          description: data.description,
          created_at: data.created_at,
          num_of_questions: 0,
          num_of_unanswered_questions: 0,
        };
        setAmas((oldArray) => [newAma, ...oldArray]);
        setShowModal({ ...showModal, create: false });
        setNewAma({
          title: "",
          description: "",
        });
        setErrors({});
      })
      .catch((error) => {
        setErrors(error.response.data);
        if (error.response.status !== 400) {
          showErrorToast("Something went wrong, please try again!");
        }
      })
      .finally(() => {
        setIsLoading({ ...isLoading, createAma: false });
      });
  };

  const handleEditSave = (e) => {
    e.preventDefault();

    setIsLoading({ ...isLoading, modal: true });

    userApi
      .updateAma(
        amaSelection.uuid,
        amaSelection.title,
        amaSelection.description,
        csrf
      )
      .then((response) => {
        const data = response.data;

        const newAmas = amas.map((ama) => {
          if (ama.uuid === amaSelection.uuid) {
            return {
              ...ama,
              title: data.title,
              description: data.description,
            };
          }
          return ama;
        });

        setAmas(newAmas);
        setAmaSelection(null);
        setShowModal({ ...showModal, edit: false });
        setErrors({});
      })
      .catch((error) => {
        setErrors(error.response.data);
      })
      .finally(() => setIsLoading({ ...isLoading, modal: false }));
  };

  const deleteAma = (e) => {
    e.preventDefault();

    setIsLoading({ ...isLoading, modal: true });

    userApi
      .deleteAma(amaSelection.uuid, csrf)
      .then(() => {
        setAmas((current) =>
          current.filter((ama) => {
            return ama.uuid !== amaSelection.uuid;
          })
        );
        setShowModal({ ...showModal, delete: false });
        setAmaSelection(null);
      })
      .catch(() => {
        showErrorToast("Something went wrong, please try again!");
      })
      .finally(() => {
        setIsLoading({ ...isLoading, modal: false });
      });
  };

  const getAmas = () => {
    userApi
      .getAmas()
      .then((response) => {
        const data = response.data;
        const newList = data.map((obj) => {
          return { ...obj, created_at: new Date(obj.created_at) };
        });
        setAmas(newList.sort((a, b) => b.created_at - a.created_at));
        setErrors({});
      })
      .catch((error) => {
        if (error.response.status === 403) {
          getSession();
        }
        showErrorToast("Something went wrong, please try again!");
      })
      .finally(() => {
        setIsLoading({ ...isLoading, site: false });
      });
  };

  const getUsername = () => {
    authApi
      .getUsername()
      .then((response) => {
        newAma.title === "" &&
          response.data.username &&
          setNewAma({
            ...newAma,
            title: response.data.username + "'s AMA",
          });
      })
      .catch();
  };

  const onClickSort = (sortBy) => {
    setAmas(() => {
      return sortByDate(sortBy);
    });
  };

  const sortByDate = (type) => {
    const newList = amas.map((obj) => {
      return { ...obj, created_at: new Date(obj.created_at) };
    });

    return newList.sort((a, b) =>
      type === 0 ? b.created_at - a.created_at : a.created_at - b.created_at
    );
  };

  useEffect(() => {
    getAmas();
    getUsername();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {showModal.create && !isLoading.site && (
        <EditCreateModal
          modalTitle="Create new AMA"
          submitButtonText="Create AMA"
          title={newAma.title}
          onTitleChange={handleTitleChange}
          errorTitle={setErrors.title}
          description={newAma.description}
          onDescriptionChange={handleDescriptionChange}
          onClickClose={() => {
            setShowModal({ ...showModal, create: false });
            setAmaSelection({});
            setErrors({});
          }}
          onClickSubmit={createAma}
          isLoading={isLoading.modal}
          loadingText="Creating AMA..."
          errors={errors}
        />
      )}
      {showModal.edit && (
        <EditCreateModal
          modalTitle="Edit AMA"
          submitButtonText="Save"
          title={amaSelection.title}
          onTitleChange={(e) =>
            setAmaSelection({
              ...amaSelection,
              title: e.target.value,
            })
          }
          description={amaSelection.description}
          onDescriptionChange={(e) => {
            setAmaSelection({
              ...amaSelection,
              description: e.target.value,
            });
          }}
          onClickClose={() => {
            setShowModal({ ...showModal, edit: false });
            setErrors({});
            setAmaSelection({});
          }}
          onClickSubmit={handleEditSave}
          isLoading={isLoading.modal}
          loadingText="Saving..."
          errors={errors}
        />
      )}
      {showModal.delete && (
        <DeleteModal
          title="Delete AMA"
          message={"Do you really want to delete " + amaSelection.title + "?"}
          deleteButton="Delete AMA"
          loadingText="Deleting AMA..."
          isLoading={isLoading.modal}
          onClickClose={() => {
            setShowModal({ ...showModal, delete: false });
            setAmaSelection({});
            setErrors({});
          }}
          onClickDelete={deleteAma}
        />
      )}
      {isLoading.site ? (
        <div className="flex flex-1 content-center items-center center-content self-center min-h-screen">
          <LoadingSpinner />
        </div>
      ) : (
        <div className="flex flex-1 min-h-screen content-center flex-col pt-36 md:pt-56 2xl:pt-72 pb-20 center-content self-center max-w-4xl px-6 md:px-10 w-full">
          <div className="flex flex-col">
            <div className="flex flex-col tiny:flex-row mb-8">
              <Title title="Your AMAs" />
              <button
                id="btn-create-ama"
                className="rounded-full p-3 px-6 w-fit h-fit bg-purple-500 hover:bg-purple-700 text-white font-medium tiny:ml-auto mt-4 tiny:mt-0"
                onClick={() => setShowModal({ ...showModal, create: true })}
              >
                Create AMA
              </button>
            </div>
            <div className="mb-4">
              <Dropdown
                sortByList={[
                  { name: "Newest", id: 0 },
                  { name: "Oldest", id: 1 },
                ]}
                onClickSort={onClickSort}
              />
            </div>
          </div>
          {!amas.length ? (
            <p className="text-center">You don't have any AMAs yet!</p>
          ) : (
            <>
              <ul aria-label="Your AMAs">
                {amas.map((ama) => (
                  <MyAmaItem
                    key={ama.uuid}
                    title={ama.title}
                    dateCreated={ama.created_at}
                    questionsCount={ama.num_of_questions}
                    questionsUnansweredCount={ama.num_of_unanswered_questions}
                    id={ama.uuid}
                    onClickEdit={() => {
                      setAmaSelection({
                        uuid: ama.uuid,
                        title: ama.title,
                        description: ama.description,
                      });
                      setShowModal({ ...showModal, edit: true });
                    }}
                    onClickDelete={() => {
                      setAmaSelection({
                        title: ama.title,
                        uuid: ama.uuid,
                      });
                      setShowModal({ ...showModal, delete: true });
                    }}
                  />
                ))}
              </ul>
            </>
          )}
        </div>
      )}
      <Footer />
      <ToastContainer />
    </>
  );
}
