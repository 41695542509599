import Input from "./InputHero";
import imageOne from "../assets/hero-image-1.png";
import imageTwo from "../assets/hero-image-2.png";
import imageThree from "../assets/hero-image-3.png";
import questionmark from "../assets/landing-page-questionmark.png";
import backgroundRight from "../assets/landing-page-bg-right.png";
import backgroundLeft from "../assets/landing-page-bg-left.png";
import backgroundBottom from "../assets/landing-page-bg-bottom.png";
import { ReactComponent as DownArrow } from "../assets/landing-page-arrow-down.svg";
import { Link } from "react-router-dom";

export default function HeroHome(props) {
  return (
    <>
      <section className="flex justify-center min-h-screen h-fit pt-48 md:pt-52 pb-52 px-6 sm:px-12 xl:px-24 xxxl:px-72 md:mx-20 relative">
        <button
          onClick={() => props.onClickScroll(2)}
          className="p-4 absolute bottom-6 left-auto right-auto cursor-pointer hover:bg-purple-100 rounded-full aspect-square"
        >
          <DownArrow alt="" className="w-6 stroke-purple-500" />
        </button>
        <div className="grid grid-cols-2 gap-4 center-content items-center justify-center">
          <div className="col-span-2 lg:col-span-1 place-self-center mb-4 pr-0 lg:pr-6 relative">
            <img
              src={questionmark}
              alt="Questionmark"
              className="absolute hidden lg:block sm:left-[-5%] md:left-[-18%] top-[-16%] h-[160%] mm:h-[140%] sm:h-[125%]"
            />
            <div className="text-center lg:text-start text-[3rem] xl:text-6xl xxxl:text-[6rem] font-extrabold tracking-tight leading-none text-purple-500 mb-7">
              <h1>
                Ask me <br className="hidden lg:block" />
                anything
              </h1>
              <h1 className="text-black">made easy</h1>
            </div>
            <p className="text-center lg:text-start max-w-2xl mb-6 text-[#7f7293] lg:mb-8 text-md xl:text-lg">
              Easily create your own AMA's. Our platform offers a clean and
              modern interface, making it super fast and easy to create and host
              your own AMA's.
            </p>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                props.onClick();
              }}
              className="flex flex-col mm:flex-row mm:justify-center lg:!justify-start h-12"
            >
              <Input
                required={false}
                value={props.username}
                onChange={props.onChange}
              />
              <button className="inline-flex rounded-full items-center justify-center px-5 py-3 ml-0 mm:ml-3 mt-4 mm:mt-0 text-base font-medium text-center text-white bg-purple-500 hover:bg-purple-600 whitespace-nowrap">
                Get started
                <svg
                  className="w-5 h-5 ml-2 -mr-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </form>
          </div>
          <img
            src={imageOne}
            className="lg:col-span-1 hidden lg:block w-[90%] place-self-center"
            alt="mockup"
          />
        </div>
      </section>
      <section
        id="section-2"
        className="w-full bg-[radial-gradient(50%_50%_at_0%_0%,#b07ef2,#9e5ff2)] min-h-screen h-fit flex center-content items-center justify-center px-6 mm:px-10 md:px-20 relative"
      >
        <button
          onClick={() => props.onClickScroll(3)}
          className="p-4 absolute bottom-6 left-auto right-auto cursor-pointer hover:bg-white/10 rounded-full aspect-square"
        >
          <DownArrow alt="" className="w-6 stroke-white" />
        </button>
        <img
          src={backgroundRight}
          alt=""
          className="absolute right-0 h-[60%] xl:h-[80%]"
        />
        <div className="flex self-center center-content items-center justify-center">
          <div className="hidden lg:mt-0 lg:flex mr-10 xl:mr-32 relative">
            <img src={imageTwo} className={"w-[60vh] z-10"} alt="mockup "></img>
          </div>
          <div className="place-self-center max-w-lg lg:max-w-[50%] xl:max-w-2xl mb-4 pr-0 lg:pr-6">
            <div className="text-4xl xl:text-6xl font-bold leading-none text-purple-500 mb-7">
              <h1 className="text-white">Your own AMA</h1>
              <h1 className="text-white">with just a few clicks</h1>
            </div>
            <p className="max-w-2xl mb-6 text-white lg:mb-8 text-md xl:text-lg">
              Get your AMA up and running in no time. Our easy-to-use platform
              allows you to create as many AMA's as you want with just a few
              clicks.
            </p>
            <button
              type="button"
              onClick={props.onClick}
              className="inline-flex rounded-full items-center justify-center px-8 py-3 mt-4 mm:mt-0 text-base font-medium text-center bg-white hover:bg-purple-100 whitespace-nowrap"
            >
              Get started
              <svg
                className="w-5 h-5 ml-2 -mr-1"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      </section>
      <section
        id="section-3"
        className="min-h-screen h-fit bg-purple-50 w-full flex center-content items-center justify-center px-6 mm:px-10 md:px-20"
      >
        <img
          src={backgroundLeft}
          alt=""
          className="absolute left-0 h-[60%] md:h-[80%]"
        />
        <div className="flex self-center center-content items-center justify-center">
          <div className="place-self-center max-w-lg lg:max-w-[50%] xl:max-w-2xl mb-4 pr-0 mr-0 xl:mr-10 xxxl:mr-32">
            <div className="text-4xl xl:text-6xl font-bold leading-none text-purple-500 mb-7">
              <h1 className="text-purple-500">Easy to use</h1>
            </div>
            <p className="max-w-2xl mb-6 lg:mb-8 text-md xl:text-lg">
              Our platform streamlines the AMA process, making it simple for
              your community to ask questions and browse through already
              answered ones.
            </p>
            <button
              type="button"
              onClick={props.onClick}
              className="inline-flex rounded-full items-center justify-center px-8 py-3 mt-4 mm:mt-0 text-base font-medium text-center bg-purple-500 text-white hover:bg-purple-600 whitespace-nowrap"
            >
              Get started
              <svg
                className="w-5 h-5 ml-2 -mr-1"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
          <div className="hidden lg:mt-0 lg:flex relative">
            <img src={imageThree} className={"w-[60vh] z-10"} alt="mockup " />
          </div>
        </div>
      </section>
      <section className="flex flex-col items-center bg-[radial-gradient(30%_50%_at_50%_100%,#bf91f2,#9e5ff2)] pt-22 pb-32 px-6 mm:px-10 relative">
        <img
          src={backgroundBottom}
          alt=""
          className="absolute bottom-0 max-w-fit h-[40%] lg:h-[80%]"
        />
        <h2 className="text-3xl lg:text-4xl xl:text-6xl font-bold text-center leading-none text-white mb-12 z-20">
          Any questions left?
        </h2>
        <p className="text-center text-white z-20 text-md xl:text-lg">
          If you still have any questions, check out or FAQ. <br />
          Also feel free to contact us at any time!
        </p>
        <Link
          to="/help"
          className="rounded-full w-full sm:w-fit sm:px-32 py-3 mt-6 mb-4 font-medium text-center bg-white hover:bg-purple-100 z-20"
        >
          See FAQ
        </Link>
        <Link
          to="/contact"
          className="text-white font-medium hover:text-black z-20"
        >
          Contact Us
        </Link>
      </section>
    </>
  );
}
