import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Footer from "../components/Footer";
import LoadingSpinner from "../components/LoadingSpinner";
import Title from "../components/Title";
import * as amaApi from "../api/amaApi";
import Avvvatars from "avvvatars-react";
import { useErrorToast } from "../hooks/useErrorToast";

export default function SearchPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState(searchParams.get("q") || "");
  const [searchResults, setSearchResults] = useState([]);

  const showErrorToast = useErrorToast();

  function handleSubmit(event) {
    event.preventDefault();

    searchQuery && setSearchParams({ q: searchQuery });
  }

  useEffect(() => {
    const searchForUser = () => {
      setIsLoading(true);

      if (searchQuery !== searchParams) {
        setSearchQuery(searchParams.get("q"));
      }

      if (searchParams.get("q") === "") {
        setIsLoading(false);
        return;
      }

      amaApi
        .searchForUser(searchParams.get("q"))
        .then((response) => {
          setSearchResults(response.data.results);
        })
        .catch(() => {
          showErrorToast("Something went wrong, please try again!");
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    searchForUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <>
      <div className="flex flex-1 min-h-screen content-center flex-col pt-36 md:pt-56 2xl:pt-72 pb-20 center-content self-center max-w-4xl px-6 md:px-10 w-full">
        <div className="flex flex-col tiny:flex-row mb-8">
          <Title title="Search" />
        </div>
        <form className="flex items-center h-[4.5rem] p-3 bg-white border rounded-full">
          <div className="relative w-full h-full mr-4">
            <label htmlFor="search" className="sr-only">
              Search
            </label>
            <div className="flex absolute inset-y-0 left-0 items-center pl-4 pointer-events-none h-full">
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-gray-400 dark:text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <input
              id="search-field"
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
              type="text"
              className="border h-full rounded-full border-gray-300 block w-full pl-12 pr-4"
              placeholder="Search"
              required
            />
          </div>
          <button
            id="search-button"
            onClick={handleSubmit}
            className="h-full bg-purple-500 text-white text-sm font-medium aspect-square sm:aspect-auto sm:px-10 cursor-pointer shadow-sm hover:bg-purple-600 rounded-full"
          >
            <div className="flex sm:hidden items-center content-center w-full h-full">
              <svg
                aria-hidden="true"
                className="w-full h-full p-3 text-white"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <span className="hidden sm:block">Search</span>
          </button>
        </form>
        {isLoading ? (
          <div className="flex flex-1 mt-[20%] self-center">
            <LoadingSpinner />
          </div>
        ) : (
          <div
            id="search-results"
            className="bg-white border rounded-3xl mt-4 divide-y"
          >
            {searchResults.length === 0 ? (
              <p className="p-6">No users found</p>
            ) : (
              searchResults.map((result) => (
                <Link
                  to={"/" + result.username}
                  key={result.username}
                  className="flex items-center py-6 px-6 cursor-pointer hover:bg-neutral-50 first:rounded-t-3xl last:rounded-b-3xl"
                >
                  <Avvvatars border={false} size={46} value={result.username} />
                  <p className="ml-2 text-lg">{result.username}</p>
                </Link>
              ))
            )}
          </div>
        )}
      </div>
      <Footer />
      <ToastContainer />
    </>
  );
}
