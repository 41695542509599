export default function About() {
  return (
    <main className="flex flex-col flex-1 pt-36 md:pt-56 2xl:pt-72 pb-32 min-h-screen w-full">
      <div className="self-center max-w-5xl w-full xl:max-w-4/6 px-8 sm:px-24 md:px-32">
        <div className="">
          <h1 className="sm:text-4xl text-3xl mb-4">About</h1>
          <hr />
        </div>
        <p className="mt-4 mb-4">Information according to § 5 TMG:</p>
        <div className="flex flex-col md:flex-row">
          <div className="mb-10 md:mb-0">
            <p>
              <b>LoveCode UG (haftungsbeschränkt)</b>
              <br />
              <br />
              Hebbelstraße 15
              <br />
              25563 Wrist
              <br />
              Germany
              <br />
              <br />
              Register Court: Amtsgericht Pinneberg
              <br />
              Register Number: HRB 14785 PI
              <br />
              <br />
              Authorized Managing Director:
              <br />
              Fabian Niklas Froh
            </p>
          </div>
          <div className="ml-0 md:ml-auto">
            <p>
              <b>Contact information:</b>
              <br />
              <br />
              E-Mail: <a href="mailto:support@askm.ee">support@askm.ee</a>
              <br />
              <br />
              <a
                className="text-blue-500 hover:underline"
                href="www.lovecode.xyz"
              >
                https://www.lovecode.xyz
              </a>
            </p>
          </div>
        </div>
      </div>
    </main>
  );
}
