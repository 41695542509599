import { useSuccessToast } from "../hooks/useSuccessToast";

export default function CopyLink(props) {
  const url = "askm.ee/ama/" + props.id.split("-")[0];
  const showSuccessToast = useSuccessToast();

  const onClickCopy = () => {
    navigator.clipboard
      .writeText(process.env.REACT_APP_BASE_URL + "/ama/" + props.id)
      .then(() => {
        showSuccessToast("Copied to clipboard", "copied");
      });
  };

  return (
    <>
      <button
        onClick={onClickCopy}
        className="flex bg-purple-500 hover:bg-purple-700 rounded-xl h-full aspect-square ml-2 items-center justify-center sxm:hidden"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          className="fill-white"
        >
          <path d="M8.465 11.293c1.133-1.133 3.109-1.133 4.242 0l.707.707 1.414-1.414-.707-.707c-.943-.944-2.199-1.465-3.535-1.465s-2.592.521-3.535 1.465L4.929 12a5.008 5.008 0 0 0 0 7.071 4.983 4.983 0 0 0 3.535 1.462A4.982 4.982 0 0 0 12 19.071l.707-.707-1.414-1.414-.707.707a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.122-2.121z"></path>
          <path d="m12 4.929-.707.707 1.414 1.414.707-.707a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.122 2.121c-1.133 1.133-3.109 1.133-4.242 0L10.586 12l-1.414 1.414.707.707c.943.944 2.199 1.465 3.535 1.465s2.592-.521 3.535-1.465L19.071 12a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0z"></path>
        </svg>
      </button>
      <div className="border bg-white hidden sxm:flex rounded-full justify-between items-center py-2 w-fit sxm:w-auto">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          className="fill-gray-500 ml-4"
        >
          <path d="M8.465 11.293c1.133-1.133 3.109-1.133 4.242 0l.707.707 1.414-1.414-.707-.707c-.943-.944-2.199-1.465-3.535-1.465s-2.592.521-3.535 1.465L4.929 12a5.008 5.008 0 0 0 0 7.071 4.983 4.983 0 0 0 3.535 1.462A4.982 4.982 0 0 0 12 19.071l.707-.707-1.414-1.414-.707.707a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.122-2.121z"></path>
          <path d="m12 4.929-.707.707 1.414 1.414.707-.707a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.122 2.121c-1.133 1.133-3.109 1.133-4.242 0L10.586 12l-1.414 1.414.707.707c.943.944 2.199 1.465 3.535 1.465s2.592-.521 3.535-1.465L19.071 12a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0z"></path>
        </svg>
        <p
          id="ama-url"
          onClick={onClickCopy}
          className="w-full outline-none bg-transparent ml-1 cursor-pointer select-none"
          type="text"
        >
          {url}
        </p>
        <button
          id="btn-copy-ama-url"
          onClick={onClickCopy}
          className="bg-purple-500 text-white rounded-full text-sm py-2 px-5 mr-2 hover:bg-purple-600"
        >
          Copy
        </button>
      </div>
    </>
  );
}
