import { Link, useNavigate, useParams } from "react-router-dom";
import Question from "../components/Question";
import { useEffect, useState } from "react";
import * as amaApi from "../api/amaApi";
import { useErrorToast } from "../hooks/useErrorToast";
import { useAuth } from "../contexts/AuthContext";
import { reportFields } from "../constants/reportFields";
import { optionFields } from "../constants/optionFields";
import { handleSendReport } from "../utils/reportUtils";
import ReportModal from "../components/Modal/ReportModal";
import { ToastContainer } from "react-toastify";
import Footer from "../components/Footer";
import { useSuccessToast } from "../hooks/useSuccessToast";
import { ArrowLeftIcon } from "@heroicons/react/20/solid";
import Title from "../components/Title";
import LoadingSpinner from "../components/LoadingSpinner";
import useVote from "../hooks/useVote";

export default function QuestionPage() {
  const { id } = useParams();
  const { csrf } = useAuth();

  const showErrorToast = useErrorToast();
  const showSuccessToast = useSuccessToast();
  const navigate = useNavigate();
  const { onHandleVote, votes } = useVote(csrf);

  const [question, setQuestion] = useState(null);
  const [ama, setAma] = useState([]);
  const [report, setReport] = useState(null);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState({
    question: true,
    report: false,
  });

  const onClickVote = (id, type, oldVote) => {
    onHandleVote({
      id: id,
      type: type,
      oldVote: oldVote,
      onSuccess: (data) => {
        setQuestion({
          ...question,
          votes: data.votes,
        });
      },
      onFailure: () => {
        showErrorToast("Something went wrong, please try again!");
      },
    });
  };

  const handleOnClickOption = (e, uuid) => {
    if (e.target.value === "copy-link") {
      navigator.clipboard
        .writeText(process.env.REACT_APP_BASE_URL + "/question/" + uuid)
        .then(() => {
          showSuccessToast("Copied to clipboard", "copied");
        });
    } else {
      setReport(e.target.value);
    }
  };

  const getQuestion = () => {
    amaApi
      .getQuestion(id)
      .then((res) => {
        const data = res.data;
        const { question, ama } = data;

        setQuestion(question);
        setAma(ama);
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading({ ...isLoading, question: false });
      });
  };

  useEffect(() => {
    getQuestion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {report && (
        <ReportModal
          content={reportFields.find((field) => field.value === report)}
          errors={errors}
          onClickClose={() => {
            setReport(null);
          }}
          onClickSendReport={(report) => {
            handleSendReport(
              report,
              question.uuid,
              null,
              isLoading,
              setIsLoading,
              setErrors,
              setReport,
              () => {},
              csrf,
              showSuccessToast,
              showErrorToast
            );
          }}
          isLoading={isLoading.report}
        />
      )}

      <div className="flex flex-1 content-center flex-col center-content self-center max-w-4xl min-h-screen pt-36 md:pt-72 pb-20 w-full px-6 md:px-10">
        {question && ama ? (
          <>
            <div className="sm:col-span-2 flex h-fit flex-col mb-10">
              <Title title={ama.title} />
              <Link
                reloadDocument={true}
                to={`/${ama.username}`}
                className="text-purple-500 hover:text-purple-600 w-fit mt-1 text-lg font-medium"
              >
                {ama.username}
              </Link>
            </div>

            <div
              onClick={() => navigate(`/ama/${ama.uuid}/`)}
              className="flex items-center mb-4 hover:cursor-pointer text-purple-500 hover:text-purple-700 group w-fit"
            >
              <ArrowLeftIcon className="h-6 text-black" />
              <p className="font-semibold pl-1">Go back to AMA</p>
            </div>

            <Question
              index={0}
              key={question.uuid}
              id={question.uuid}
              question={question.question}
              answer={question.answer}
              votes={question.votes}
              voted={votes[question.uuid] ?? question.voted}
              dateAnswered={question.answered_at}
              dateAsked={question.asked_at}
              onClickVote={(id, type) => onClickVote(id, type, question.voted)}
              onClickOption={(e) => {
                handleOnClickOption(e, question.uuid);
              }}
              hideChevron={true}
              options={question.answer ? optionFields[1] : optionFields[2]}
              onToggle={() => {}}
              active={question.answer}
              isUnanswered={!question.answer}
            />
          </>
        ) : isLoading.question ? (
          <div
            id="loading-spinner"
            className="flex flex-1 content-center items-center center-content self-center"
          >
            <LoadingSpinner />
          </div>
        ) : (
          <p className="self-center justify-center">
            Sorry, this question does not exist.
          </p>
        )}
      </div>
      <ToastContainer />
      <Footer />
    </>
  );
}
