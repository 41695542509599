export default function InputHero(props) {
  return (
    <div className="flex items-center h-full">
      <div className="relative w-full h-full text-gray-700 font-normal">
        <div className="flex absolute inset-y-0 left-0 items-center pl-5 pointer-events-none h-full font-medium">
          askm.ee/
        </div>
        <input
          type="text"
          id="username-input"
          value={props.value}
          onChange={props.onChange}
          className="border rounded-full h-full border-gray-300 focus:ring-blue-500 focus:border-blue-500 block w-full pl-23 p-2.5 dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="yourname"
          required={props.required ?? true}
        />
      </div>
    </div>
  );
}
