import classnames from "classnames";
import ErrorText from "./ErrorText";
import HoneypotInput from "./HoneypotInput";

export default function Contact(props) {
  return (
    <main className="flex flex-col flex-1 justify-center min-h-screen h-fit pb-20 md:mx-20 py-40 px-6 xm:px-12 md:px-0">
      <section>
        <div className="lg:py-16 mx-auto max-w-screen-md">
          <h2 className="mb-4 text-4xl font-bold text-center">Contact Us</h2>
          <p className="mb-8 lg:mb-16 font-light text-center sm:text-xl">
            Got a technical issue? Want to send feedback about a beta feature?
            Let us know.
          </p>
          <form
            onSubmit={props.onSubmit}
            className="space-y-4 sm:space-y-8 flex flex-col"
          >
            <div>
              <label htmlFor="email" className="block mb-2 text-sm font-medium">
                Your email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={props.inputs.email || ""}
                onChange={props.onChange}
                className={classnames(
                  props.errors.email && "border-red-500",
                  "border text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:focus:ring-primary-500 dark:focus:border-primary-500"
                )}
                placeholder="name@mail.com"
                required
              />
              {props.errors.email && (
                <ErrorText className="mt-2" text={props.errors.email} />
              )}
            </div>
            <div>
              <label
                htmlFor="subject"
                className="block mb-2 text-sm font-medium "
              >
                Subject
              </label>
              <input
                type="text"
                id="subject"
                name="subject"
                onChange={props.onChange}
                value={props.inputs.subject || ""}
                className={classnames(
                  props.errors.subject && "border-red-500",
                  "block p-3 w-full text-sm rounded-lg border focus:ring-primary-500 focus:border-primary-500 dark:focus:ring-primary-500 dark:focus:border-primary-500"
                )}
                placeholder="Let us know how we can help you"
                required
              />
              {props.errors.subject && (
                <ErrorText className="mt-2" text={props.errors.subject} />
              )}
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="message"
                className="block mb-2 text-sm font-medium "
              >
                Your message
              </label>
              <textarea
                id="message"
                rows="6"
                name="message"
                onChange={props.onChange}
                value={props.inputs.message || ""}
                className={classnames(
                  props.errors.message && "border-red-500",
                  "block p-2.5 w-full text-sm  rounded-lg border focus:ring-primary-500 focus:border-primary-500  dark:focus:ring-primary-500 dark:focus:border-primary-500"
                )}
                placeholder="What is your request about?"
                required
              />
              {props.errors.message && (
                <ErrorText className="mt-2" text={props.errors.message} />
              )}
            </div>
            <HoneypotInput
              handleChange={props.onChange}
              value={props.inputs.telnumber || ""}
              type="tel"
              name="telnumber"
              id="telnumber"
              labelText="Phone number"
              labelFor="telnumber"
            />
            {props.errors.non_field_errors && (
              <ErrorText
                className="w-full text-center"
                text={props.errors.non_field_errors}
              />
            )}
            <button
              type="submit"
              className="py-3 px-5 text-sm font-medium text-center bg-purple-500 self-center text-white rounded-full bg-primary-700 sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              disabled={props.isLoading}
            >
              {props.isLoading ? (
                <>
                  <svg
                    role="status"
                    className="inline mr-3 w-4 h-4 text-white animate-spin"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="#E5E7EB"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentColor"
                    />
                  </svg>
                  Sending...
                </>
              ) : (
                <p>Send message</p>
              )}
            </button>
          </form>
        </div>
      </section>
    </main>
  );
}
