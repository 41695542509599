import api from "./axios";

export const getQuestions = (
  uuid,
  withUser = false,
  isAuthenticated = false
) => {
  const endpoints = [`/ama/${uuid}/`];
  withUser && endpoints.push("/api/account/");

  return Promise.all(
    endpoints.map((url) => {
      if (withUser && url === "/api/account/" && !isAuthenticated) {
        return null;
      }

      return api
        .get(url, {
          withCredentials: true,
        })
        .catch(() => {
          if (url === "/api/account/") {
            return null;
          }
        });
    })
  );
};

export const getQuestion = (id) => {
  return api.get(`/ama/question/${id}/`, {
    withCredentials: true,
  });
};

export const getUnansweredQuestions = (uuid) => {
  return api.get(`/ama/${uuid}/questions/`, {
    withCredentials: true,
  });
};

export const submitQuestion = (question, uuid, csrf) => {
  return api.post(
    "/ama/question/create/",
    { question: question, ama_uuid: uuid },
    {
      headers: {
        "X-CSRFToken": csrf,
        "Content-Type": "application/json",
      },
      withCredentials: true,
    }
  );
};

export const submitVote = (id, voteType, csrf) => {
  return api.put(
    `/ama/question/vote/${id}/`,
    {
      vote_type: voteType,
    },
    {
      headers: {
        "X-CSRFToken": csrf,
        "Content-Type": "application/json",
      },
      withCredentials: true,
    }
  );
};

export const getProfile = (username) => {
  return api.get(`/ama/user/${username}/`);
};

export const searchForUser = (searchQuery) => {
  return api.get(`/ama/users/?search=${searchQuery}`);
};

export const getFeatured = () => {
  return api.get("/ama/featured/", { withCredentials: true });
};
