import { cloneElement } from "react";
import { Children } from "react";
import { useTexts } from "../../hooks/useTexts";
import PrivacyPolicySection from "./PrivacyPolicySection";

export const PrivacyPolicyContent = ({ children }) => {
  const texts = useTexts(children);

  return (
    <>
      <PrivacyPolicySection>
        <ul>
          {texts.map((text, index) => (
            <li key={index}>{text}</li>
          ))}
        </ul>
      </PrivacyPolicySection>

      {Children.map(children, (child, index) =>
        cloneElement(child, {
          index: index + 1,
        })
      )}
    </>
  );
};
