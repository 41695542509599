export default function MyAmaStatsItem(props) {
  return (
    <div
      className={`${props.twStyle} p-2 border rounded-xl flex flex-col justify-center items-center x`}
      data-testid={props.dataTestId}
    >
      <h1 className="text-md mm:text-xl font-bold">{props.count}</h1>
      <p className="text-mm mm:text-base font-normal">{props.text}</p>
    </div>
  );
}
