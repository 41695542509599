import { useState } from "react";
import FaqItem from "./FaqItem";
import Title from "./Title";

export default function FAQ(props) {
  const [questions, setQuestions] = useState(props.questions);

  const handleToggle = (index) => {
    const newQuestions = questions.map((question) => {
      if (question.id === index) {
        return {
          ...question,
          isExpanded: !question.isExpanded,
        };
      }
      return question;
    });

    setQuestions(newQuestions);
  };

  return (
    <main className="flex flex-col flex-1 h-screen min-h-screen justify-center items-center">
      <div className="flex flex-1 content-center flex-col center-content self-center max-w-4xl pt-36 md:pt-56 2xl:pt-72 pb-20 w-full px-6 md:px-10">
        <div className="mb-10">
          <div className="flex flex-col items-center justify-center">
            <Title title={"Frequently Asked Questions"} />
            <p className="mb-8"></p>
          </div>
        </div>
        {questions.map((item) => (
          <FaqItem
            key={item.id}
            question={item.question}
            answer={item.answer}
            onToggle={() => handleToggle(item.id)}
            active={item.isExpanded}
          />
        ))}
      </div>
    </main>
  );
}
