import classNames from "classnames";
import { UnmountClosed } from "react-collapse";

export default function FaqItem(props) {
  return (
    <div className="w-full">
      <div
        id="accordion-collapse"
        data-accordion="collapse"
        className={`${
          props.active ? " divider-x-2 border-collapse" : "border-none"
        } mb-4 flex-1`}
      >
        <div className="">
          <div className="flex flex-col w-full">
            <button
              type="button"
              onClick={props.onToggle}
              className={`${
                props.active ? "rounded-t-xl" : "rounded-xl border delay-150 "
              } transition-all ease-in-out flex items-center border justify-between w-full p-5 font-medium text-left h-full bg-white`}
              data-accordion-target="#accordion-collapse-body-1"
              aria-expanded="true"
              aria-controls="accordion-collapse-body-1"
            >
              <p className="text-sm mm:text-base font-semibold [word-break:break-word]">
                {props.question}
              </p>
              <svg
                data-accordion-icon
                className={`${
                  props.active ? "rotate-180" : "rotate-0"
                } flex-none transform transition duration-200 ease-in-out w-6 h-6`}
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
          <UnmountClosed
            theme={{
              collapse: classNames(
                "transition-all duration-150 border border-t-0 rounded-b-xl bg-white",
                !props.active && "opacity-0"
              ),
              content: "p-5",
            }}
            isOpened={props.active}
          >
            <div className="text-sm mm:text-base [word-break:break-word]">
              {props.answer}
            </div>
          </UnmountClosed>
        </div>
      </div>
    </div>
  );
}
