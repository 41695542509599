import * as contactApi from "../api/contactApi";

export const handleSendReport = (
  report,
  selectedQuestionAnswer,
  ama,
  isLoading,
  setIsLoading,
  setErrors,
  setReport,
  setSelectedQuestionAnswer,
  csrf,
  showSuccessToast,
  showErrorToast
) => {
  setIsLoading({ ...isLoading, report: true });

  switch (report.value) {
    case "report-answer":
    case "report-question":
      report.value === "report-answer"
        ? (report.selection = "answer")
        : (report.selection = "question");
      report.uuid = selectedQuestionAnswer;

      contactApi
        .reportQuestionAnswer(report, csrf)
        .then(() => {
          showSuccessToast("Report has been received");
          setErrors({});
          setReport(null);
          setSelectedQuestionAnswer(null);
        })
        .catch((error) => {
          error.response.status >= 400 && error.response.status < 500
            ? setErrors(error.response.data)
            : showErrorToast("Something went wrong, please try again!");
        })
        .finally(() => {
          setIsLoading({ ...isLoading, report: false });
        });
      break;
    case "report-ama":
    case "report-user":
      report.value === "report-ama"
        ? (report.ama_uuid = ama.uuid)
        : (report.username = ama.username);
      contactApi
        .reportUserOrAMA(report, csrf)
        .then(() => {
          showSuccessToast("Report has been received");
          setErrors({});
          setReport(null);
        })
        .catch((error) => {
          error.response.status >= 400 && error.response.status < 500
            ? setErrors(error.response.data)
            : showErrorToast("Something went wrong, please try again!");
        })
        .finally(() => {
          setIsLoading({ ...isLoading, report: false });
        });
      break;
    default:
      break;
  }
};
