import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";
import SignupPage from "./pages/Signup";
import LoginPage from "./pages/Login";
import LandingPage from "./pages/Landing";
import ResetPasswordPage from "./pages/PasswordReset";
import AnswerQuestionsPage from "./pages/AnswerQuestions";
import AmaPage from "./pages/Ama";
import { useAuth } from "./contexts/AuthContext";
import { RequireAuth } from "./components/RequireAuth";
import { HomeLayout } from "./components/HomeLayout";
import PageNotFoundPage from "./pages/PageNotFound";
import PrivacyPolicyPage from "./pages/PrivacyPolicy";
import AboutPage from "./pages/About";
import ScrollToTop from "./components/ScrollToTop";
import FaqPage from "./pages/Faq";
import ContactPage from "./pages/Contact";
import ProfilePage from "./pages/Profile";
import SettingsPage from "./pages/Settings";
import Header from "./components/Header";
import MyAmasPage from "./pages/MyAmas";
import SetNewPasswordPage from "./pages/SetNewPassword";
import SearchPage from "./pages/Search";
import QuestionPage from "./pages/Question";
import Featured from "./pages/Featured";

export default function App() {
  const { loading } = useAuth();

  if (window.location.hostname.startsWith("app.")) {
    window.location.href = window.location.href.replace("app.", "");
  }

  return (
    !loading && (
      <>
        <ScrollToTop />
        <div className="flex flex-col min-h-screen h-fit overflow-hidden bg-purple-50 center">
          <Header />
          <Routes>
            <Route element={<HomeLayout />}>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/signup" element={<SignupPage />} />
              <Route path="/" element={<LandingPage />} />
              <Route path="/home" element={<LandingPage />} />
            </Route>
            <Route path="/reset-password" element={<ResetPasswordPage />} />
            <Route
              path="/set-new-password/:uidb64/:token"
              element={<SetNewPasswordPage />}
            />
            <Route path="/featured" element={<Featured />} />
            <Route path="/ama/:id" element={<AmaPage />} />
            <Route path="/question/:id" element={<QuestionPage />} />
            <Route path="/search" element={<SearchPage />} />
            <Route path="/:username" element={<ProfilePage />} />
            <Route element={<RequireAuth />}>
              <Route path="/my-amas" element={<MyAmasPage />} />
              <Route
                path="/answer-questions/:id"
                element={<AnswerQuestionsPage />}
              />
              <Route path="/settings" element={<SettingsPage />} />
            </Route>
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/help" element={<FaqPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/page-not-found" element={<PageNotFoundPage />} />
            <Route
              path="*"
              element={<Navigate to="/page-not-found" replace />}
            />
          </Routes>
        </div>
      </>
    )
  );
}
