import { Link } from "react-router-dom";
import FormAction from "./FormAction";
import HoneypotInput from "./HoneypotInput";
import Input from "./Input";

export default function Signup(props) {
  return (
    <>
      <form onSubmit={props.handleSubmit} className="mt-8 space-y-6">
        <div>
          {props.fields.map((field) => (
            <Input
              key={field.id}
              handleChange={props.onChange}
              value={props.signupState[field.id]}
              labelText={field.labelText}
              labelFor={field.labelFor}
              id={field.id}
              name={field.name}
              username={field.username}
              type={field.type}
              isRequired={field.isRequired}
              placeholder={field.placeholder}
              error={props.errors[field.id]}
              autofocus={field.autofocus}
              autoComplete={field.autoComplete}
              isToggle={field.isToggle}
            />
          ))}
          <HoneypotInput
            handleChange={props.onChange}
            value={props.signupState["telnumber"] || ""}
            type="tel"
            name="telnumber"
            id="telnumber"
            labelText="Phone number"
            labelFor="telnumber"
          />
          <div className="flex items-center justify-center mb-6">
            <div className="flex items-center h-5">
              <input
                id="terms"
                type="checkbox"
                value={props.checkbox}
                onChange={props.onChangeCheckbox}
                className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300"
                onInvalid={(e) =>
                  e.target.setCustomValidity(
                    "You need to read and agree to the privacy policy to create an account."
                  )
                }
                onInput={(e) => e.target.setCustomValidity("")}
                required
              />
            </div>
            <label htmlFor="terms" className="ml-2 text-sm text-gray-600 ">
              I have read and agree to the{" "}
              <Link
                to="/privacy-policy"
                className="font-medium text-purple-600 hover:underline"
              >
                privacy policy.
              </Link>
            </label>
          </div>
          {props.errors.non_field_errors && (
            <div className="text-red-500 flex flex-col self-center items-center">
              <p>{props.errors.non_field_errors}</p>
            </div>
          )}
          <FormAction
            text="Signup"
            isLoading={props.isLoading}
            loadingText="Creating account..."
            isChecked={props.checkbox}
          />
        </div>
      </form>
      <p className="text-center text-sm text-gray-600 mt-5">
        Already have an account?{" "}
        <Link
          to="/login"
          className="font-medium text-purple-500 hover:text-purple-600"
        >
          Login
        </Link>
      </p>
    </>
  );
}
