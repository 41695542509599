import { GlobeAltIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";

export default function PageNotFoundPage() {
  return (
    <>
      <div className="flex flex-1 content-center min-h-screen h-fit justify-center flex-col center-content self-center max-w-lg w-full py-40 px-6 sm:px-0">
        <div className="flex flex-col items-center">
          <GlobeAltIcon className="text-purple-500 max-w-[5rem] mb-4" />
          <h1 className="text-4xl font-bold mb-4">Page not found</h1>
          <p>We looked everywhere, but we couldn't find the page.</p>
          <Link to="/home/" className="rounded-full text-center w-full py-3 px-4 text-sm font-medium text-white bg-purple-500 enabled:hover:bg-purple-600 mt-8">
            Go home
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
}
