import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import Footer from "../components/Footer";
import Ama from "../components/Ama";
import Amas from "../components/Amas";
import LoadingSpinner from "../components/LoadingSpinner";
import * as amaApi from "../api/amaApi";
import { ToastContainer } from "react-toastify";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";

export default function ProfilePage() {
  const [amas, setAmas] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const { username } = useParams();

  const fetchAmas = () => {
    amaApi
      .getProfile(username)
      .then((response) => {
        if (response.data) {
          const newList = response.data.map((obj) => {
            return { ...obj, created_at: new Date(obj.created_at) };
          });
          setAmas(newList.sort((a, b) => b.created_at - a.created_at));
        }
      })
      .catch(() => {
        navigate("/page-not-found/", { replace: true });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchAmas();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="flex flex-1 content-center items-center center-content self-center min-h-screen">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          {amas.length === 0 && (
            <div className="flex flex-1 content-center min-h-screen h-fit justify-center flex-col center-content self-center max-w-lg w-full py-40 px-6 sm:px-0">
              <div className="flex flex-col items-center">
                <ExclamationCircleIcon className="text-purple-500 max-w-[5rem] mb-4" />
                <h1 className="text-4xl font-bold mb-4 text-center">
                  No AMA's yet
                </h1>
                <p>This user has not created any AMA's yet.</p>
                <p>Maybe if you come back later.</p>
                <Link
                  to="/home/"
                  className="rounded-full text-center w-full py-3 px-4 text-sm font-medium text-white bg-purple-500 enabled:hover:bg-purple-600 mt-8"
                >
                  Go home
                </Link>
              </div>
            </div>
          )}
          {amas.length === 1 && <Ama uuid={amas.shift().uuid} />}
          {amas.length > 1 && <Amas amas={amas} title={username + "'s AMAs"} />}
        </>
      )}
      <Footer />
      <ToastContainer />
    </>
  );
}
