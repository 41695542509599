import classNames from "classnames";
import ErrorText from "./ErrorText";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid";
import { useState } from "react";

export default function ModalInput({
  id,
  label,
  placeholder,
  value,
  onChange,
  errors,
  required = true,
  type,
  autoFocus = false,
  autoComplete,
  isToggle = false,
}) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="mb-5">
      <label
        htmlFor={id}
        className="block mb-2 text-sm font-medium text-gray-900"
      >
        {label}
      </label>
      <div className="relative">
        <input
          id={id}
          type={showPassword ? "text" : type}
          value={value}
          onChange={onChange}
          className={classNames(
            errors.id && "border-red-500",
            "block p-2.5 w-full text-sm text-gray-900 rounded-lg border focus:ring-blue-500 focus:border-blue-500 bg-gray-100 border-gray-300 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 pr-10"
          )}
          placeholder={placeholder}
          autoFocus={autoFocus}
          autoComplete={autoComplete}
          required={required}
        />
        {isToggle && (
          <button
            className="absolute h-full aspect-square right-0 top-0 p-3"
            name={showPassword ? `Hide ${type}` : `Show ${type}`}
            type="button"
            onClick={(e) => {
              e.preventDefault();
              setShowPassword(!showPassword);
            }}
          >
            {showPassword ? (
              <EyeIcon className="h-full" />
            ) : (
              <EyeSlashIcon className="h-full" />
            )}
          </button>
        )}
      </div>
      {errors[id] && <ErrorText className="mt-2" text={errors[id]} />}
    </div>
  );
}
