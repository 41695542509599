import { toast } from "react-toastify";

export function useSuccessToast() {
  function showSuccessToast(message, toastId) {
    toast.success(message, {
      toastId: toastId,
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }

  return showSuccessToast;
}
