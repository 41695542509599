import { useState } from "react";
import Question from "./Question";

export default function Questions(props) {
  const [clicked, setClicked] = useState("0");

  const handleToggle = (index) => {
    if (clicked === index) {
      return setClicked("0");
    }
    setClicked(index);
  };

  return (
    <div>
      {!props.questions.length && props.noQuestionsText !== "" && (
        <div className="flex items-center justify-center w-full">
          <p>{props.noQuestionsText}</p>
        </div>
      )}
      {props.questions.map((question, index) => (
        <Question
          index={index}
          key={question.uuid}
          id={question.uuid}
          question={question.question}
          answer={question.answer}
          votes={question.votes}
          voted={props.votes[question.uuid] ?? question.voted}
          dateAnswered={question.answered_at}
          dateAsked={question.asked_at}
          onClickVote={(id, type) => {
            props.onClickVote(id, type, question.voted);
          }}
          onClickOption={(e) => {
            props.onClickOption(e, question.uuid);
          }}
          options={props.options}
          onToggle={() => handleToggle(question.uuid)}
          active={clicked === question.uuid}
        />
      ))}
    </div>
  );
}
