import AnswerQuestionItem from "./AnswerQuestionItem";
import Dropdown from "./Dropdown";

export default function AnswerQuestions(props) {
  return (
    <div>
      <div>
        <div className="flex mb-4 items-center">
          <h1 className="font-bold text-2xl">Questions</h1>
          <Dropdown
            className="ml-auto"
            sortByList={[
              { name: "Newest", id: 0 },
              { name: "Oldest", id: 1 },
            ]}
            onClickSort={props.onClickSort}
          />
        </div>
        <ul aria-label="questions">
          {!props.questions.length && <p>No unanswered questions</p>}
          {props.questions.map((question) => (
            <AnswerQuestionItem
              key={question.uuid}
              username={props.ama.username}
              testId={"answer-question-item-" + question.uuid}
              onClickAnswer={(id) => props.onClickAnswer(id)}
              onClickDelete={(id) => props.onClickDelete(id)}
              question={question}
            />
          ))}
        </ul>
      </div>
    </div>
  );
}
