import { useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import checkmark from "../assets/check_mark_3d.png";
import * as contactApi from "../api/contactApi";

export default function ContactPage() {
  const { csrf } = useAuth();

  const [errors, setErrors] = useState({});
  const [inputs, setInputs] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmitContact = (e) => {
    e.preventDefault();
    setIsLoading(true);

    contactApi
      .submitContactForm(inputs, csrf)
      .then(() => {
        setErrors({});
        setSubmitted(true);
      })
      .catch((error) => {
        setErrors(error.response.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      {submitted ? (
        <div className="min-h-screen h-fit flex flex-col items-center justify-center">
          <img className="h-40" src={checkmark} alt="Checkmark" />
          <p className="text-3xl font-bold my-2">Thank you!</p>
          <p className="text-xl">Your submission has been received!</p>
        </div>
      ) : (
        <Contact
          onChange={handleChange}
          inputs={inputs}
          onSubmit={handleSubmitContact}
          errors={errors}
          isLoading={isLoading}
        />
      )}
      <Footer />
    </>
  );
}
