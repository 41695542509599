import classNames from "classnames";
import { useEffect, useRef, useState } from "react";

export default function Tabs({ className, tabs, activeTab, onTabClick }) {
  const slideRef = useRef();
  const buttonRefs = useRef([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Update window width when the window is resized
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Update the active tab UI
  useEffect(() => {
    const selectedButton = buttonRefs.current.find(
      (button) => button.dataset.name === activeTab.name
    );
    if (!selectedButton) return;

    const {
      offsetWidth: slideWidth,
      offsetLeft: slideLeft,
      clientHeight: slideHeight,
      offsetTop,
      parentNode: { offsetTop: parentOffsetTop },
    } = selectedButton;
    const slideTop = offsetTop - parentOffsetTop;

    slideRef.current.style.width = `${slideWidth}px`;
    slideRef.current.style.transform = `translateX(${slideLeft}px)`;
    slideRef.current.style.height = `${slideHeight}px`;
    slideRef.current.style.top = `${slideTop}px`;
  }, [activeTab, windowWidth]);

  return (
    <div className={classNames("relative", className)}>
      <div
        ref={slideRef}
        className={classNames(
          "transition-transform duration-300 absolute bg-purple-500 rounded-full z-10"
        )}
      />
      <div className="w-auto flex p-2 bg-white rounded-full border">
        {tabs.map((tab, index) => (
          <button
            id={tab.name}
            key={tab.name}
            ref={(element) => (buttonRefs.current[index] = element)}
            data-name={tab.name}
            className={classNames(
              activeTab.name === tab.name ? "text-white" : "text-gray-800",
              "rounded-full py-1 px-3 duration-300 relative z-20 grow"
            )}
            onClick={() => onTabClick(tab)}
          >
            {tab.name.charAt(0).toUpperCase() + tab.name.slice(1)}
          </button>
        ))}
      </div>
    </div>
  );
}
