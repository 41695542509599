import FeaturedAMA from "../components/FeaturedAMA";
import Footer from "../components/Footer";
import Title from "../components/Title";
import * as amaApi from "../api/amaApi";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper/modules";
import { useState } from "react";
import { Navigate } from "react-router-dom";
import { useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import useVote from "../hooks/useVote";
import { useErrorToast } from "../hooks/useErrorToast";

export default function Featured() {
  const [isLoading, setIsLoading] = useState(true);
  const [amas, setAmas] = useState([]);

  const { csrf } = useAuth();
  const { onHandleVote, votes } = useVote(csrf);
  const showErrorToast = useErrorToast();

  let firstFiveAmas;
  let restAmas = [];

  if (window.innerWidth < 1024) {
    firstFiveAmas = [...amas];
  } else {
    firstFiveAmas = amas.slice(0, 5);
    restAmas = amas.slice(5);
  }

  if (amas.length <= 5) {
    restAmas = [...firstFiveAmas];
  }

  const onClickVote = (id, amaId, type, oldVote) => {
    onHandleVote({
      id: id,
      type: type,
      oldVote: oldVote,
      onSuccess: (data) => {
        // Find the index of the AMA in the array
        const amaIndex = amas.findIndex((ama) => ama.uuid === amaId);

        const newAmas = [...amas];
        // Find the question and update the votes
        newAmas[amaIndex].questionanswer_set.find(
          (question) => question.uuid === id
        ).votes = data.votes;

        setAmas(newAmas);
      },
      onFailure: () => {
        showErrorToast("Something went wrong, please try again!");
      },
    });
  };

  const fetchAmas = () => {
    amaApi
      .getFeatured()
      .then((response) => {
        if (response.data) {
          setAmas(response.data);
        }
      })
      .catch(() => {
        Navigate("/page-not-found/", { replace: true });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchAmas();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="flex flex-1 content-center flex-col center-content self-center min-h-screen pt-36 md:pt-48 pb-20 w-full px-6 md:px-10">
        <div className="mb-10">
          <div className="sm:col-span-2 flex h-fit flex-col mb-16 items-center">
            <Title title="Featured" />
            <p className="text-sm sm:text-md pl-0.5 mt-2 whitespace-pre-wrap">
              Check out these featured AMAs
            </p>
          </div>
          {isLoading ? (
            <div className="w-full h-full flex items-center justify-center">
              <p>Loading...</p>
            </div>
          ) : amas.length === 0 ? (
            <div className="w-full h-full flex items-center justify-center">
              <p>No featured AMAs found</p>
            </div>
          ) : (
            <>
              {firstFiveAmas.length > 0 && (
                <Swiper
                  spaceBetween={20}
                  slidesPerView={1}
                  loop={true}
                  autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                  }}
                  breakpoints={{
                    // when window width is >= 640px
                    1280: {
                      slidesPerView: 1,
                    },
                    // when window width is >= 768px
                    1300: {
                      slidesPerView: 2,
                    },
                    // when window width is >= 1024px
                    1840: {
                      slidesPerView: 3,
                    },
                  }}
                  modules={[Autoplay]}
                  className="my-swiper"
                >
                  {[
                    ...Array(Math.ceil(4 / firstFiveAmas.length)).fill(
                      firstFiveAmas
                    ),
                  ]
                    .flat()
                    .slice(0, 4)
                    .map((ama, index) => (
                      <SwiperSlide key={index} style={{ height: "auto" }}>
                        <FeaturedAMA
                          ama={ama}
                          onHandleVote={onClickVote}
                          votes={votes}
                        />
                      </SwiperSlide>
                    ))}
                </Swiper>
              )}

              {restAmas.length > 0 && (
                <Swiper
                  slidesPerView={1}
                  spaceBetween={20}
                  centeredSlides={false}
                  breakpoints={{
                    1250: {
                      slidesPerView: 1,
                    },
                    1530: {
                      slidesPerView: 2.5,
                      centeredSlides: true,
                    },
                    1840: {
                      slidesPerView: 2,
                    },
                    2350: {
                      slidesPerView: 4,
                    },
                  }}
                  loop={true}
                  autoplay={{
                    delay: 6000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                  }}
                  modules={[Autoplay]}
                  className="w-full h-full mt-6 my-swiper !hidden lg:!block"
                >
                  {[...Array(Math.ceil(6 / restAmas.length)).fill(restAmas)]
                    .flat()
                    .slice(0, 6)
                    .map((ama, index) => (
                      <SwiperSlide key={index} style={{ height: "auto" }}>
                        <FeaturedAMA
                          ama={ama}
                          onHandleVote={onClickVote}
                          votes={votes}
                        />
                      </SwiperSlide>
                    ))}
                </Swiper>
              )}
            </>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}
