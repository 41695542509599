export const optionFields = [
  {
    id: 0,
    value: "options-ama",
    options: [
      { id: 0, value: "report-ama", text: "Report AMA" },
      { id: 1, value: "report-user", text: "Report User" },
    ],
    show: false,
  },
  {
    id: 1,
    value: "options-qa",
    options: [
      { id: 0, value: "report-question", text: "Report Question" },
      { id: 1, value: "report-answer", text: "Report Answer" },
      { id: 2, value: "copy-link", text: "Copy Link" },
    ],
    show: false,
  },
  {
    id: 2,
    value: "options-q",
    options: [
      { id: 0, value: "report-question", text: "Report Question" },
      { id: 1, value: "copy-link", text: "Copy Link" },
    ],
    show: false,
  },
];
