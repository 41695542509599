import { toast } from "react-toastify";

export function useErrorToast() {
  function showErrorToast(message) {
    toast.error(message, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  }

  return showErrorToast;
}
