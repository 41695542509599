import { Link } from "react-router-dom";
import FormAction from "./FormAction";
import Input from "./Input";

export default function Login(props) {
  return (
    <>
      <form onSubmit={props.handleSubmit} className="space-y-6">
        <div className="-space-y-px">
          {props.fields.map((field) => (
            <Input
              key={field.id}
              handleChange={props.handleChange}
              value={props.loginState[field.id]}
              labelText={field.labelText}
              labelFor={field.labelFor}
              id={field.id}
              name={field.name}
              type={field.type}
              isRequired={field.isRequired}
              placeholder={field.placeholder}
              error={props.errors[field.id]}
              autofocus={field.autofocus}
              autoComplete={field.autoComplete}
              isToggle={field.isToggle}
            />
          ))}
        </div>
        {props.errors.non_field_errors && (
          <div className="text-red-500 flex flex-col self-center items-center">
            <p>{props.errors.non_field_errors}</p>
          </div>
        )}
        <div className="text-sm flex w-full">
          <Link
            to="/reset-password"
            className="font-medium text-purple-600 hover:text-purple-500 ml-auto"
          >
            Forgot your password?
          </Link>
        </div>
        <FormAction
          text="Login"
          loadingText="Logging in..."
          isLoading={props.isLoading}
        />
      </form>
      <p className="text-center text-sm text-gray-600 mt-5">
        Don't have an account yet?{" "}
        <Link
          to="/signup"
          className="font-medium text-purple-600 hover:text-purple-500"
        >
          Signup
        </Link>
      </p>
    </>
  );
}
