import { ReactComponent as Logo } from "./../assets/askmee-logo.svg";

export default function QuestionSticker({ username, stickerRef, question }) {
  return (
    <div
      ref={stickerRef}
      className="z-[-9999] top-0 left-0 absolute min-w-[12rem] max-w-xs w-fit flex flex-col"
      aria-hidden="true"
    >
      <div className="h-8"></div>
      <div>
        <div className="relative p-4 rounded-t-2xl bg-white">
          <div className="-top-8 h-14 flex items-center justify-center absolute m-auto left-0 right-0 bg-purple-500 p-3 rounded-full aspect-square">
            <Logo alt="logo" className="text-white" />
          </div>
          <p className="text-center pt-5">{question.question}</p>
        </div>
        <div className="bg-purple-500 rounded-b-2xl py-2 px-4 flex items-center justify-center">
          <p className="text-white break-all">askm.ee/{username}</p>
        </div>
      </div>
    </div>
  );
}
