import ErrorText from "../ErrorText";

export default function ModalTextArea({
  description,
  value,
  onChange,
  errors,
  placeholder,
  name
}) {
  return (
    <div>
      <label
        htmlFor="textarea"
        className="block mb-2 text-sm font-medium text-gray-900"
      >
        {description}
      </label>
      <textarea
        id="textarea"
        value={value}
        onChange={onChange}
        rows="4"
        className={`${
          errors.question && "border-red-500"
        } block p-2.5 w-full text-sm text-gray-900 rounded-lg border focus:ring-blue-500 focus:border-blue-500 bg-gray-100 border-gray-300 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500`}
        placeholder={placeholder}
        required
      />
      {errors[name] && <ErrorText text={errors[name]} className="mt-2" />}
    </div>
  );
}
