import api from "./axios";

export const createAma = (title, description, csrf) => {
  return api.post(
    "/ama/create/",
    {
      title: title,
      description: description,
    },
    {
      headers: {
        "X-CSRFToken": csrf,
        "Content-Type": "application/json",
      },
      withCredentials: true,
    }
  );
};

export const deleteAma = (uuid, csrf) => {
  const URL = "/ama/delete/" + uuid + "/";
  return api.delete(URL, {
    headers: { "X-CSRFToken": csrf },
    withCredentials: true,
  });
};

export const getAmas = () => {
  return api.get("/ama/my-amas/", {
    withCredentials: true,
  });
};

export const getQuestions = (id) => {
  return api.get(`/ama/${id}/questions/`, {
    withCredentials: true,
  });
};

export const deleteQuestion = (question, csrf) => {
  return api.delete(`/ama/question/delete/${question}/`, {
    headers: { "X-CSRFToken": csrf },
    withCredentials: true,
  });
};

export const postAnswer = (uuid, answer, csrf) => {
  return api.put(
    `/ama/question/update/${uuid}/`,
    {
      answer: answer,
    },
    {
      headers: {
        "X-CSRFToken": csrf,
        "Content-Type": "application/json",
      },
      withCredentials: true,
    }
  );
};

export const updateAma = (uuid, title, description, csrf) => {
  return api.put(
    `/ama/update/${uuid}/`,
    {
      title: title,
      description: description,
    },
    {
      headers: {
        "X-CSRFToken": csrf,
        "Content-Type": "application/json",
      },
      withCredentials: true,
    }
  );
};

export const getSettings = () => {
  const urls = ["/api/usersettings/", "/ama/my-amas/", "/api/account/"];
  return Promise.all(
    urls.map((url) =>
      api.get(url, {
        withCredentials: true,
      })
    )
  );
};

export const updateSettings = (settings, csrf) => {
  return api.put(
    "/api/usersettings/",
    {
      show_all_amas_on_profile_page: settings.show_all_amas_on_profile_page,
      profile_page_ama_uuid: settings.profile_page_ama_uuid,
    },
    {
      headers: {
        "X-CSRFToken": csrf,
        "Content-Type": "application/json",
      },
      withCredentials: true,
    }
  );
};

export const setNewPassword = (
  currentPassword,
  newPassword,
  confirmNewPassword,
  csrf
) => {
  return api.put(
    "/api/account/change-password/",
    {
      new_password: newPassword,
      new_password_check: confirmNewPassword,
      old_password: currentPassword,
    },
    {
      headers: {
        "X-CSRFToken": csrf,
        "Content-Type": "application/json",
      },
      withCredentials: true,
    }
  );
};

export const setNewEmail = (currentPassword, newEmail, csrf) => {
  return api.put(
    "/api/account/change-email/",
    {
      password: currentPassword,
      new_email: newEmail,
    },
    {
      headers: {
        "X-CSRFToken": csrf,
        "Content-Type": "application/json",
      },
      withCredentials: true,
    }
  );
};
