import classnames from "classnames";

export default function Title(props) {
  return (
    <h1
      style={{ wordBreak: "break-word" }}
      className={classnames(
        props.className,
        "text-2xl sm:text-4xl font-bold min-w-0 break-words"
      )}
    >
      {props.title}
    </h1>
  );
}
