import { useState } from "react";
import AnswerQuestions from "../components/AnswerQuestions";
import EditButton from "../components/EditButton";
import Title from "../components/Title";
import CreateModal from "../components/CreateModal";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import Footer from "../components/Footer";
import CopyLink from "../components/CopyLink";
import DeleteModal from "../components/DeleteModal";
import EditCreateModal from "../components/EditCreateModal";
import { ToastContainer } from "react-toastify";
import LoadingSpinner from "../components/LoadingSpinner";
import * as userApi from "../api/userApi";
import { useErrorToast } from "../hooks/useErrorToast";
import classNames from "classnames";

export default function AnswerQuestionsPage() {
  const [show, setShow] = useState(false);
  const [selection, setSelection] = useState(null);
  const [ama, setAma] = useState([]);
  const [answer, setAnswer] = useState("");
  const [amaDeleteSelection, setAmaDeleteSelection] = useState(null);
  const [amaEditSelection, setAmaEditSelection] = useState({
    isVisible: false,
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState({
    site: true,
    delete: false,
    edit: false,
    answer: false,
  });
  const [questions, setQuestions] = useState([]);

  const { csrf } = useAuth();
  const showErrorToast = useErrorToast();
  const { id } = useParams();

  const fetchQuestions = () => {
    userApi
      .getQuestions(id)
      .then((response) => {
        const data = response.data;
        setQuestions(data.questionanswer_set);
        setAma(data);
        setAmaEditSelection({
          ...amaEditSelection,
          title: data.title,
          description: data.description,
        });
      })
      .catch(() => {
        showErrorToast("Something went wrong, please try again!");
      })
      .finally(() => {
        setIsLoading({ ...isLoading, site: false });
      });
  };

  const onClickAnswer = (id) => {
    setSelection(
      questions.find((question) => {
        return question.uuid === id;
      })
    );
    setShow(true);
  };

  const onClickDelete = () => {
    setIsLoading({ ...isLoading, delete: true });

    userApi
      .deleteQuestion(amaDeleteSelection, csrf)
      .then(() => {
        setAmaDeleteSelection(null);
        setQuestions((current) =>
          current.filter((question) => {
            return question.uuid !== amaDeleteSelection;
          })
        );
      })
      .catch(() => {
        showErrorToast("Something went wrong, please try again!");
      })
      .finally(() => setIsLoading({ ...isLoading, delete: false }));
  };

  const onClickSendAnswer = (e) => {
    e.preventDefault();

    setIsLoading({ ...isLoading, answer: true });

    userApi
      .postAnswer(selection.uuid, answer, csrf)
      .then(() => {
        setQuestions((current) =>
          current.filter((question) => {
            return question.uuid !== selection.uuid;
          })
        );
        setAnswer("");
        setShow(false);
        setErrors({});
      })
      .catch((error) => {
        setErrors(error.response.data);
      })
      .finally(() => setIsLoading({ ...isLoading, answer: false }));
  };

  const handleEditSave = (e) => {
    e.preventDefault();

    setIsLoading({ ...isLoading, edit: true });

    userApi
      .updateAma(
        ama.uuid,
        amaEditSelection.title,
        amaEditSelection.description,
        csrf
      )
      .then((response) => {
        const data = response.data;
        setAma({ ...ama, title: data.title, description: data.description });
        setAmaEditSelection({ ...amaEditSelection, isVisible: false });
        setErrors({});
      })
      .catch((error) => {
        setErrors(error.response.data);
      })
      .finally(() => setIsLoading({ ...isLoading, edit: false }));
  };

  const onClickCloseModal = () => {
    setShow(false);
  };

  const onClickSort = (sortBy) => {
    setQuestions(() => {
      return sortByDate(sortBy);
    });
  };

  const sortByDate = (type) => {
    const newList = questions.map((obj) => {
      return { ...obj, asked_at: new Date(obj.asked_at) };
    });

    return newList.sort((a, b) =>
      type === 0 ? b.asked_at - a.asked_at : a.asked_at - b.asked_at
    );
  };

  useEffect(() => {
    fetchQuestions();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {show && (
        <CreateModal
          title={selection.question}
          inputDescription="Your answer"
          buttonText="Answer"
          placeholder="Enter your answer..."
          inputValue={answer}
          handleChange={(e) => setAnswer(e.target.value)}
          onClickClose={onClickCloseModal}
          onClickSubmit={onClickSendAnswer}
          isLoading={isLoading.answer}
          loadingText="Submitting..."
          errors={errors}
        />
      )}
      {amaDeleteSelection && (
        <DeleteModal
          title="Delete Question"
          message="Do you really want to delete this question?"
          deleteButton="Delete"
          onClickClose={() => setAmaDeleteSelection(null)}
          onClickDelete={onClickDelete}
          isLoading={isLoading.delete}
          loadingText="Deleting..."
        />
      )}
      {amaEditSelection.isVisible && (
        <EditCreateModal
          modalTitle="Edit AMA"
          submitButtonText="Save"
          title={amaEditSelection.title}
          onTitleChange={(e) =>
            setAmaEditSelection({
              ...amaEditSelection,
              title: e.target.value,
            })
          }
          description={amaEditSelection.description}
          onDescriptionChange={(e) => {
            setAmaEditSelection({
              ...amaEditSelection,
              description: e.target.value,
            });
          }}
          onClickClose={() => {
            setAmaEditSelection({ ...amaEditSelection, isVisible: false });
            setErrors({});
          }}
          onClickSubmit={handleEditSave}
          isLoading={isLoading.edit}
          loadingText="Saving..."
          errors={errors}
        />
      )}
      {isLoading.site ? (
        <div className="flex flex-1 content-center items-center center-content self-center min-h-screen">
          <LoadingSpinner />
        </div>
      ) : (
        <div className="flex flex-1 content-center flex-col center-content self-center min-h-screen pt-36 md:pt-56 2xl:pt-72 pb-20 max-w-4xl w-full px-6 md:px-10">
          {ama.title ? (
            <>
              <div className="mb-10">
                <div className="flex h-fit sxm:flex-col sm:!flex-row mb-8 sm:items-start">
                  <div className="flex h-fit items-center sxm:mb-4 mr-10">
                    <Title title={ama.title} />
                    <div className="w-4"></div>
                    <EditButton
                      onClickEdit={() =>
                        setAmaEditSelection({
                          ...amaEditSelection,
                          isVisible: true,
                        })
                      }
                    />
                  </div>
                  <div className="ml-0 sm:ml-auto">
                    <CopyLink id={id} />
                  </div>
                </div>
                <div className={classNames(!ama.description && "hidden")}>
                  <h2 className="font-medium text-lg">Description</h2>
                  <p className="whitespace-pre-wrap">
                    {ama.description
                      ? ama.description
                      : "This AMA has no description"}
                  </p>
                </div>
              </div>
              <AnswerQuestions
                onClickAnswer={(id) => onClickAnswer(id)}
                onClickDelete={(id) => setAmaDeleteSelection(id)}
                questions={questions}
                ama={ama}
                onClickSort={onClickSort}
              />
            </>
          ) : (
            <p className="text-center">Couldn't load AMA</p>
          )}
        </div>
      )}
      <Footer />
      <ToastContainer limit={1} />
    </>
  );
}
