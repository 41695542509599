import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid";
import classnames from "classnames";
import { useState } from "react";

export default function Input({
  handleChange,
  value,
  labelText,
  labelFor,
  id,
  type,
  isRequired = false,
  placeholder,
  customClass,
  error,
  autofocus = false,
  autoComplete,
  name,
  isToggle = false,
}) {
  const errorStyle = `${error && " border-red-500"}`;
  const [showInput, setShowInput] = useState(false);

  return (
    <div className="mb-5">
      <label htmlFor={labelFor} className="sr-only">
        {labelText}
      </label>
      <div className="relative">
        <input
          onChange={handleChange}
          value={value}
          id={id}
          name={name}
          type={showInput ? "text" : type}
          required={isRequired}
          className={classnames(
            customClass,
            errorStyle,
            "rounded-full block w-full px-5 py-[0.6rem] border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm pr-10"
          )}
          placeholder={placeholder}
          autoFocus={autofocus}
          autoComplete={autoComplete}
        />
        {isToggle && (
          <button
            className="absolute h-full aspect-square right-0 top-0 p-3 pr-4"
            type="button"
            id={showInput ? `hide-${name}` : `show-${name}`}
            name={showInput ? `Hide ${name}` : `Show ${name}`}
            onClick={(e) => {
              e.preventDefault();
              setShowInput(!showInput);
            }}
          >
            {showInput ? (
              <EyeIcon className="h-full" />
            ) : (
              <EyeSlashIcon className="h-full" />
            )}
          </button>
        )}
      </div>
      <p className="mt-2 text-red-500">{error && error[0]}</p>
    </div>
  );
}
