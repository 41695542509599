import { PrivacyPolicyContent } from "./PrivacyPolicyContent";
import PrivacyPolicySection from "./PrivacyPolicySection";
import PrivacyPolicySectionHeading from "./PrivacyPolicySectionHeading";

export default function PrivacyPolicy() {
  return (
    <main className="flex flex-col flex-1 pt-36 md:pt-56 2xl:pt-72 pb-16 h-full w-full">
      <div className="px-8 xm:px-12 md:px-24 max-w-7xl w-full self-center">
        <div>
          <h1 className="text-4xl font-semibold mb-6">Privacy Policy</h1>
          <hr className="mb-4" />
        </div>
        <p className="mb-6">(Status as of 01.02.2023)</p>
        <div className="">
          <PrivacyPolicySection>
            <PrivacyPolicySectionHeading text="Short summary:" />
            <p>The usage of the website <b>askm.ee</b> leads to the processing
              of your personal data. This is required for technical reasons like displaying this site
              and also functionalities like the account creation.
              We{" "} <b>do not pass</b> any of your data on <b>to third parties</b> and strive
              to ensure the <b>best possible security</b> of your personal data.
              We try to be as <b>transparent</b> as possible in our data
              processing and with this privacy policy. However, if you have any
              questions or comments, please feel free to contact us.
            </p>
          </PrivacyPolicySection>

          <PrivacyPolicySection>
            <PrivacyPolicySectionHeading text="Introduction:" />
            <p>
              This is the privacy policy for the website <b>askm.ee</b>, which
              is a web-based platform that allows users to create and
              host their own "Ask Me Anything" (AMA) sessions. Other users can
              then ask the host of the AMA session questions, vote on answers
              and view questions that have already been answered.
              <br />
              The following privacy policy informs you about the collection, use
              and storage of personal data (hereinafter referred to as "Data")
              in the context of the use of this website. If you do not
              agree to this, you cannot register or use the website.
              <br />
              <br />
              The privacy policy informs you about the processing of your data
              in accordance with Article 13 of the General Data Protection
              Regulation (GDPR) of the European Union.
            </p>
          </PrivacyPolicySection>

          <PrivacyPolicySectionHeading text="Structure of the privacy policy" />

          <PrivacyPolicyContent>
            <PrivacyPolicySection>
              <PrivacyPolicySectionHeading text="Responsible entity" />
              <p>
                Responsible company for the domain / website "askm.ee" and the
                data that is collected from you ("data controller"):
                <br />
                <br />
                LoveCode UG (haftungsbeschraenkt)
                <br />
                Hebbelstr. 15
                <br />
                25563 Wrist
                <br />
                Germany
                <br />
                <br />
                <a
                  className="text-blue-600 hover:underline font-medium"
                  href="mailto:support@askm.ee"
                >
                  support@askm.ee
                </a>
              </p>
            </PrivacyPolicySection>

            <PrivacyPolicySection>
              <PrivacyPolicySectionHeading text="Scope of application" />
              <p>
                This privacy policy applies to the website askm.ee and all its
                sub-pages. The privacy policy can be viewed on the website at
                any time.
              </p>
            </PrivacyPolicySection>

            <PrivacyPolicySection>
              <PrivacyPolicySectionHeading text="Legal basis of the processing" />
              <p>
                The legal basis for the processing of your personal data is
                Article 6, paragraph 1 letter A) of the GDPR, once you have
                given us your consent for this processing. Your data will be
                collected and processed in accordance with the provisions of the
                Federal Data Protection Act (BDSG) of Germany and the General
                Data Protection Regulation (GDPR).
              </p>
            </PrivacyPolicySection>

            <PrivacyPolicySection>
              <PrivacyPolicySectionHeading text="Why is personal data collected?" />
              <p>
                Your personal data is collected so that you can use the website
                askm.ee. From a technical point of view, it is necessary to
                collect some data, which are listed in the following point. In
                addition, data is collected when using the contact form, to be
                able to process and answer your request.
              </p>
            </PrivacyPolicySection>

            <PrivacyPolicySection>
              <PrivacyPolicySectionHeading text="What personal data is collected and what is it used for?" />
              <p>
                When visiting the website "askm.ee" the following data is
                transmitted for technical reasons (including spam protection):
              </p>
              <ul className="space-y-1 mt-3 list-disc list-inside">
                <li>
                  <b>IP address</b>
                </li>
                <li>
                  <b>Requested page/file</b>
                </li>
                <li>
                  <b>Date and time of the request</b>
                </li>
                <li>
                  <b>Browser type or operating system</b>
                </li>
                <li>
                  <b>Amount of data transmitted</b>
                </li>
              </ul>
              <br />
              <p>
                For a short time (14 days) this data is stored on our servers.
                However, this data is only used and stored for technical reasons
                (in particular, to provide you access to this website and to detect
                spam or attacks on the servers and to be able to fend them off). We will
                not further process this data and it will never be passed on to third
                parties. An identification of individual users is also not
                carried out by us.
              </p>
              <hr className="my-4" />
              <p>
                When registering / creating a user profile (reason for the
                collection in brackets):
              </p>
              <ul className="space-y-1 mt-3 list-disc list-inside">
                <li>
                  <b>Username</b> (Unique identifier)
                </li>
                <li>
                  <b>E-Mail address</b> (Authentication, contact & resetting
                  your password)
                </li>
                <li>
                  <b>Password</b> (For protecting your account)
                </li>
                <li>
                  <b>Last login</b> (date and time of last login)
                </li>
                <li>
                  <b>Date joined</b> (date and time of signup)
                </li>
              </ul>
              <br />
              <p>
                This information is used to create a unique account for the
                user, allowing them to access their personalized information,
                such as their AMA's, answers, and voting history. The e-mail
                address is used for account verification, contact and to reset
                your password.
              </p>
              <br />
              <p>
                When creating an AMA (Ask me anything) session, the following
                data is collected:
              </p>
              <ul className="space-y-1 mt-3 list-disc list-inside">
                <li>
                  <b>Title</b>
                </li>
                <li>
                  <b>Description</b>
                </li>
                <li>
                  <b>Date and time of creation</b>
                </li>
              </ul>
              <br />
              <p>When answering questions, the following data is collected:</p>
              <ul className="space-y-1 mt-3 list-disc list-inside">
                <li>
                  <b>Your answer</b>
                </li>
                <li>
                  <b>The date and time when you answered</b>
                </li>
              </ul>
              <br />
              <p>
                This data of your user profile is stored until your account is
                deleted or you revoke your declaration of consent.
              </p>
              <hr className="my-4" />
              <p>
                When using the contact form, the following data is collected:
              </p>
              <ul className="space-y-1 mt-3 list-disc list-inside">
                <li>
                  <b>Name</b>
                </li>
                <li>
                  <b>E-mail address</b>
                </li>
                <li>
                  <b>Subject and message</b>
                </li>
              </ul>
              <br />
              <p>
                This data will be stored until the completion of your request or
                until revocation.
              </p>
            </PrivacyPolicySection>

            <PrivacyPolicySection>
              <PrivacyPolicySectionHeading text="What data can other users see from you?" />
              <p>Other users can see the following data from you:</p>
              <ul className="space-y-1 mt-3 list-disc list-inside">
                <li>
                  <b>Your username</b>
                </li>
                <li>
                  <b>Your AMA (Ask me anything) sessions, including:</b>
                  <ol className="pl-5 mt-2 space-y-1 list-decimal list-inside">
                    <li>The title of the AMA</li>
                    <li>The description of the AMA</li>
                    <li>The questions and your answers</li>
                    <li>The time and date you answered each question</li>
                  </ol>
                </li>
                <li>
                  <b>
                    Questions you ask in other users AMA sessions, including:
                  </b>
                  <ol className="pl-5 mt-2 space-y-1 list-decimal list-inside">
                    <li>The time and date you asked the question</li>
                  </ol>
                </li>
              </ul>
            </PrivacyPolicySection>

            <PrivacyPolicySection>
              <PrivacyPolicySectionHeading text="How is your personal data protected?" />
              <p>
                Your data will not be disclosed to third parties and will only
                be used for technical necessity or to respond to contact
                requests. Your data will be stored on our servers in Germany
                (Frankfurt) and thus within of the EU. When transmitting the
                data, we only use encrypted connections (TLS).
                <br />
                <br />
                We try to protect our servers as well as the website in the best
                possible way with organizational and technical measures against
                attacks and unauthorized access, so that your data neither can
                be stolen or manipulated. If we discover that our services are
                under attack, we will take appropriate action as soon as
                possible and inform you about the security of your data.
              </p>
            </PrivacyPolicySection>

            <PrivacyPolicySection>
              <PrivacyPolicySectionHeading text="How long is your personal data stored?" />
              <p>
                Your data will be stored as long as you have an account for
                askm.ee or as long as the purpose for which it was collected
                requires it. If we notice a longer period of inactivity, we may
                deactivate or delete your account. Should you decide to delete
                your account (see settings page) all your data stored with us will
                be deleted.
              </p>
            </PrivacyPolicySection>

            <PrivacyPolicySection>
              <PrivacyPolicySectionHeading text="Use of cookies" />
              <p>
                We use cookies. Cookies are small text files that are stored on
                a user's device by a website. They are used to remember user
                preferences, login information, and other details about the
                user's interactions with the website. Cookies allow websites to
                provide a more personalized and convenient experience for the
                user, as they can remember things like shopping cart items, form
                information, and other details between visits.
              </p>
              <br />
              <p>
                Our website uses only technically necessary cookies, which are
                essential for the proper functioning of the website and are
                required for the user to access and use its features. It is
                possible to deactivate these cookies in the browser, however,
                error-free functioning of our website can no longer be
                guaranteed in this case.
              </p>
              <br />
              <p>
                The difference between technically necessary cookies and other
                types of cookies is that technically necessary cookies are only
                used for the specific purpose of providing the functionality and
                services required for the website to operate properly, whereas
                other types of cookies, such as marketing or analytics cookies,
                may track the user's activity and collect personal information
                for additional purposes.
              </p>
              <br />
              <p>
                <b>We use the following cookies:</b>
              </p>
              <div className="relative mt-4">
                <table className="table-auto overflow-x-auto w-full block text-sm text-left text-gray-500  border rounded-lg">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        Name
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Lifespan
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Description
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="bg-white border-y">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                      >
                        CSRF token
                      </th>
                      <td className="px-6 py-4">1 Year</td>
                      <td className="px-6 py-4 min-w-[50rem]">
                        A CSRF (Cross-Site Request Forgery) token is a security
                        measure used to prevent unauthorized actions on a
                        website. It is a unique, random string of characters
                        that is generated by the website and included in the
                        forms and requests sent to the user's browser. The token
                        is then sent back to the server with each subsequent
                        form submission or request, allowing the website to
                        verify that the action was initiated by the user and not
                        an attacker attempting to manipulate the website from a
                        different location. The use of CSRF tokens helps to
                        ensure the privacy and security of user data by
                        protecting against malicious attacks.
                      </td>
                    </tr>
                    <tr className="bg-white">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                      >
                        Session ID
                      </th>
                      <td className="px-6 py-4">1 Year</td>
                      <td className="px-6 py-4">
                        A session ID is a unique identifier that is assigned to
                        a user when visiting the website. It is used to track
                        and manage the user's interactions with the website,
                        allowing the website to maintain a consistent state and
                        provide a personalized experience. The session ID is
                        stored on the user's device in a cookie, and is sent
                        back to the server with each subsequent request,
                        allowing the website to recognize the user and retrieve
                        their stored information. The use of session IDs helps
                        to provide a seamless and secure experience for the
                        user, while also allowing the website to track and
                        manage user data and interactions.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </PrivacyPolicySection>

            <PrivacyPolicySection>
              <PrivacyPolicySectionHeading text="Your rights as a user (revocation, information and deletion)" />
              <p>
                Within the framework of the GDPR, you are granted the following
                rights in the processing of your personal data:
                <br />
                <br /><b>Right of withdrawal</b> (according to Article 7,
                paragraph 3 GDPR):
                <br />
                You have the right to revoke your declaration
                of consent to the processing of your personal data at any time.
                For more detailed information, see 10. Right of withdrawal.
                <br />
                <br /><b>Right to information of the person concerned</b>{" "}
                (according to Article 15 GDPR):
                <br />
                You have the right to obtain
                information from us whether we process your personal data and if
                so, you have the right to information about this personal data
                or a copy thereof. In addition, you have the right to obtain
                more detailed information on the processing of your personal
                data.
                <br />
                <br /><b>Right of rectification</b> (according to article 16
                GDPR):
                <br />You have the right to have your personal data rectified
                or completed.
                <br />
                <br /><b>Right to deletion</b> (according to article 17 GDPR):
                <br />
                You have the right to have your data deleted immediately under
                certain circumstances (e.g. revocation of consent).
                <br />
                <br /><b>Right to limitation of processing</b> (according to
                article 18 GDPR):
                <br />
                You have the right to demand the limitation of
                processing of your data under certain conditions.
                <br />
                <br /><b>Right to data transfer</b> (pursuant to Article 20
                GDPR):
                <br />
                You have the right to receive your personal data and,
                under certain conditions, to have them transferred unhindered to
                another person in charge.
                <br />
                <br /><b>Right of objection</b> (according to article 21
                GDPR):
                <br />
                You have the right, under certain conditions, to object
                to the processing of your personal data. In particular, you have
                the right to object to direct advertising and related profiling
                at any time. For more detailed information, see Article 21 of
                the GDPR.
                <br />
                <br /><b>Right to complain to a supervisory authority</b>{" "}
                (pursuant to Article 77):
                <br />
                You have the right to complain to the
                competent supervisory authority if you believe that the
                processing of your personal data violates the GDPR.
              </p>
            </PrivacyPolicySection>

            <PrivacyPolicySection>
              <PrivacyPolicySectionHeading text="Right of revocation" />
              <p>
                You can decide at any time to withdraw your consent to the
                storage of your personal data (in accordance with Article 6
                GDPR). Should you decide to withdraw your consent, we will
                delete all your stored data with us.
                <br />
                <br />
                You can execute the revocation either by going to the settings page and deleting your account or by contacting us directly
                with your request at{" "}
                <a
                  className="text-blue-600 hover:underline font-medium"
                  href="mailto:support@askm.ee"
                >
                  support@askm.ee
                </a>
                .
              </p>
            </PrivacyPolicySection>

            <PrivacyPolicySection>
              <PrivacyPolicySectionHeading text="Changes to the privacy policy" />
              <p>
                Due to the addition of new features in our products and in order
                to always reflect the current legal situation, this privacy
                policy may be subject to change. The current privacy policy can
                be accessed at any time on this page. Of course you have the
                right to withdraw your declaration of consent at any time.
              </p>
            </PrivacyPolicySection>

            <PrivacyPolicySection>
              <PrivacyPolicySectionHeading text="Contact and feedback" />
              <p>
                If you have any questions, feedback or other concerns regarding
                data protection, please feel free to contact us at{" "}
                <a
                  className="text-blue-600 hover:underline font-medium"
                  href="mailto:support@askm.ee"
                >
                  support@askm.ee
                </a>{" "}
                at any time.
                <br />
                <br />
                If you discover any discrepancies in the processing of your
                personal data, you can - as described in 10. Your rights as a
                user - complain to the competent supervisory authority.
              </p>
            </PrivacyPolicySection>
          </PrivacyPolicyContent>
        </div>
      </div>
    </main>
  );
}
