import { Tab } from "@headlessui/react";
import GeneralSettings from "./GeneralSettings";
import classnames from "classnames";
import ProfileSettings from "./ProfileSettings";

export default function Settings(props) {
  return (
    <div className="w-full">
      <h1 className="text-2xl sm:text-4xl font-bold md:px-6">
        Account Settings
      </h1>
      <Tab.Group as="div" className="flex flex-col" defaultIndex={0}>
        <Tab.List className="border-b text-sm font-medium mt-3 md:mx-6">
          {props.categories.map((category) => (
            <Tab
              data-testid={category.value}
              key={category.value}
              className={({ selected }) =>
                classnames(
                  "py-2 mr-4 outline-none",
                  selected
                    ? "border-purple-500 border-b-2 "
                    : "border-slate-300"
                )
              }
            >
              {category.name}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel>
            <ProfileSettings
              account={props.account}
              onChange={props.onChangeUserSettings}
              onSubmit={props.onSubmit}
              isLoading={props.isLoading}
              errors={props.errors}
            />
          </Tab.Panel>
          <Tab.Panel>
            <GeneralSettings
              settings={props.settings}
              amas={props.amas}
              onSubmit={props.onSubmit}
              onChange={props.onChangeUserSettings}
              isLoading={props.isLoading}
              errors={props.errors}
            />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}
