import classNames from "classnames";
import { Collapse } from "react-collapse";
import MoreButton from "./MoreButton";
import Vote from "./Vote";

export default function Question({ hideChevron = false, ...props }) {
  const isUnanswered = !props.answer;

  return (
    <div className="w-full">
      <div
        id="accordion-collapse"
        data-accordion="collapse"
        className={`${
          props.active ? " divider-x-2 border-collapse" : "border-none"
        } mb-4 flex-1`}
      >
        <div className="flex flex-col w-full auto-cols-auto auto-rows-auto">
          <div
            className={classNames(
              props.active ? "rounded-t-3xl" : "rounded-3xl border delay-150",
              "flex flex-col w-full transition-all ease-in-out bg-white p-5 border"
            )}
          >
            <button
              type="button"
              onClick={props.onToggle}
              className={
                "flex items-center justify-between w-full h-full font-medium text-left"
              }
              data-accordion-target="#accordion-collapse-body-1"
              aria-expanded="true"
              aria-controls="accordion-collapse-body-1"
              disabled={isUnanswered}
            >
              <div className="">
                <p className="text-sm mm:text-base [word-break:break-word]">
                  {props.question}
                </p>
                <p className="text-xs text-slate-400 mt-1">
                  {new Date(
                    isUnanswered ? props.dateAsked : props.dateAnswered
                  ).toLocaleDateString("en-us", {
                    weekday: "long",
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                  })}
                </p>
              </div>
              {hideChevron !== true && !isUnanswered && (
                <svg
                  data-accordion-icon
                  className={`${
                    props.active ? "rotate-180" : "rotate-0"
                  } flex-none transform transition duration-200 ease-in-out w-6 h-6`}
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              )}
            </button>
            <div className="flex h-10 mt-4">
              <Vote
                index={props.index}
                id={props.id}
                onClickVote={props.onClickVote}
                votes={props.votes}
                voted={props.voted}
              />
              {props.options && (
                <MoreButton
                  buttonID={"options-question-answer-" + props.index}
                  className="ml-auto"
                  options={props.options}
                  onClick={props.onClickOption}
                />
              )}
            </div>
          </div>
          {!isUnanswered && (
            <Collapse
              theme={{
                collapse: classNames(
                  "transition-all duration-150 row-start-2 col-start-2 border border-t-0 rounded-b-3xl bg-white",
                  !props.active && "opacity-0"
                ),
                content: "p-5",
              }}
              isOpened={props.active}
            >
              <p className="text-sm mm:text-base font-light [word-break:break-word] whitespace-pre-wrap">
                {props.answer}
              </p>
            </Collapse>
          )}
        </div>
      </div>
    </div>
  );
}
