import { useParams } from "react-router-dom";
import Footer from "../components/Footer";
import Ama from "../components/Ama";
import { ToastContainer } from "react-toastify";

export default function AmaPage() {
  const { id } = useParams();

  return (
    <>
      <Ama uuid={id} />
      <ToastContainer />
      <Footer />
    </>
  );
}
