import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Search(props) {
  const [searchQuery, setSearchQuery] = useState("");

  const navigate = useNavigate();
  const inputRef = useRef(null);

  function handleSubmit(e) {
    e.preventDefault();

    navigate(searchQuery ? "/search?q=" + searchQuery : "/search");

    props.setNavBarVisible(false);
    inputRef.current.blur();
  }

  return (
    <form
      onSubmit={handleSubmit}
      className={`${props.twStyle} flex items-center h-full`}
    >
      <label htmlFor="search" className="sr-only">
        Search
      </label>
      <div className="relative w-full h-full">
        <div className="flex absolute inset-y-0 left-0 items-center pl-4 pointer-events-none h-full">
          <svg
            aria-hidden="true"
            className="w-5 h-5 text-gray-400 dark:text-gray-400"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
              clipRule="evenodd"
            ></path>
          </svg>
        </div>
        <input
          ref={inputRef}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          type="text"
          id={props.id ?? "search"}
          name="search"
          className="border rounded-full h-full border-gray-300 text-gray-900 text-sm block w-full pl-10 p-2.5"
          placeholder="Search"
        />
      </div>
    </form>
  );
}
