import * as htmlToImage from "html-to-image";
import download from "downloadjs";
import { useEffect, useRef, useState } from "react";
import QuestionSticker from "./QuestionSticker";
import { ReactComponent as DownloadStickerIcon } from "./../assets/download-sticker-icon.svg";
import Tooltip from "./Tooltip";

export default function AnswerQuestionItem(props) {
  const stickerRef = useRef(null);
  const [showSticker, setShowSticker] = useState(false);

  useEffect(() => {
    if (showSticker) {
      downloadSticker();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSticker]);

  const downloadSticker = async () => {
    if (stickerRef.current) {
      const scale = 2; // Scale factor, in this case, 2x
      const fileName = `askmee-sticker-${props.question.uuid}.png`;

      htmlToImage
        .toPng(stickerRef.current, {
          canvasWidth: stickerRef.current.offsetWidth * scale,
          canvasHeight: stickerRef.current.offsetHeight * scale,
        })
        .then((dataUrl) => {
          download(dataUrl, fileName);
        })
        .catch((error) => {
          console.error("Error generating sticker:", error);
        })
        .finally(() => {
          setShowSticker(false); // Hide the sticker after downloading
        });
    }
  };

  return (
    <div
      data-testid={props.testId}
      className="flex flex-col md:flex-row bg-white border rounded-3xl p-4 items-start md:items-center mb-3"
    >
      {showSticker && (
        <QuestionSticker
          username={props.username}
          question={props.question}
          stickerRef={stickerRef}
        />
      )}

      {/* Question Content */}
      <div className="flex flex-col justify-center ml-2 mb-4 md:mb-0">
        <p className="font-medium [word-break:break-word]">
          {props.question.question}
        </p>
        <p aria-label="Date asked" className="text-xs text-slate-400 mt-1">
          {new Date(props.question.asked_at).toLocaleDateString("en-us", {
            weekday: "long",
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
          })}
        </p>
      </div>

      {/* Buttons */}
      <div className="ml-none md:ml-auto flex flex-none w-full sm:w-auto">
        <button
          onClick={() => props.onClickAnswer(props.question.uuid)}
          className="bg-purple-500 hover:bg-purple-700 text-white font-medium p-3 rounded-xl ml-0 md:ml-3 mr-3 px-4 w-full mm:w-auto"
        >
          Answer question
        </button>
        <button
          onClick={() => props.onClickDelete(props.question.uuid)}
          aria-label="Delete question"
          className="bg-red-500 p-3 hover:bg-red-700 rounded-xl mr-3"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="#fff"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
          </svg>
        </button>
        <Tooltip text="Download question as image" position="bottom">
          <button
            onClick={() => {
              setShowSticker(true);
            }}
            aria-label="Download question as sticker"
            className="bg-blue-500 p-3 hover:bg-blue-700 rounded-xl text-white sm:aspect-square h-full"
          >
            <DownloadStickerIcon />
          </button>
        </Tooltip>
      </div>
    </div>
  );
}
