import Footer from "../components/Footer";
import PrivacyPolicy from "../components/PrivacyPolicy/PrivacyPolicy";

export default function PrivacyPolicyPage() {
  return (
    <>
      <PrivacyPolicy />
      <Footer />
    </>
  );
}
