import Footer from "../components/Footer";
import HeaderLogin from "../components/HeaderLogin";
import SetNewPassword from "../components/SetNewPassword";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { Link } from "react-router-dom";

export default function SetNewPasswordPage() {
  const [submitted, setSubmitted] = useState(false);

  return (
    <>
      <div className="flex flex-1 content-center min-h-screen h-fit justify-center flex-col center-content self-center max-w-lg w-full py-40 px-6 sm:px-0">
        {submitted ? (
          <div className="flex flex-col items-center">
            <CheckCircleIcon className="text-green-500 max-w-[5rem] mb-4" />
            <h1 className="text-4xl font-bold mb-4">Success</h1>
            <p>You've successfully updated your password.</p>
            <Link to="/login/" className="rounded-full w-full py-3 px-4 text-sm font-medium text-center text-white bg-purple-500 enabled:hover:bg-purple-600 mt-8">
              Go to login
            </Link>
          </div>
        ) : (
          <>
            <HeaderLogin heading="Set new password" />
            <SetNewPassword setSubmitted={setSubmitted} />
          </>
        )}
      </div>
      <Footer />
    </>
  );
}
