import HeaderLogin from "../components/HeaderLogin";
import Signup from "../components/Signup";
import { useState } from "react";
import { signupFields } from "../constants/formFields";
import { useAuth } from "../contexts/AuthContext";
import Footer from "../components/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useErrorToast } from "../hooks/useErrorToast";
import * as authApi from "../api/authApi";

const fields = signupFields;
let fieldsState = {};
fields.forEach((field) => (fieldsState[field.id] = ""));

export default function SignupPage() {
  const navigate = useNavigate();

  const { state } = useLocation();
  const { username } = state || {};
  const { csrf, setIsAuthenticated } = useAuth();
  const showErrorToast = useErrorToast();

  const [signupState, setSignupState] = useState(fieldsState);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [checkbox, setCheckbox] = useState(false);

  const register = () => {
    setIsLoading(true);

    authApi
      .signup(signupState, csrf)
      .then(() => {
        setIsAuthenticated(true);
        navigate("/my-amas", { replace: true });
        setErrors({});
      })
      .catch((error) => {
        if (
          error.response?.status &&
          error.response.status >= 400 &&
          error.response.status < 500
        ) {
          setErrors(error.response.data);
        } else {
          showErrorToast("Something went wrong, please try again!");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleChange = (e) =>
    setSignupState({ ...signupState, [e.target.id]: e.target.value });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      signupState.username !== "" &&
      signupState.email !== "" &&
      signupState.password !== "" &&
      signupState.confirmPassword !== ""
    ) {
      register();
    }
  };

  useEffect(() => {
    if (username != null) {
      setSignupState({ ...signupState, username: username });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="min-h-screen h-fit flex items-center justify-center lg:px-8 py-40 px-6 sm:px-0">
        <div className="max-w-lg w-full space-y-8">
          <HeaderLogin
            heading="Signup to create an account"
            paragraph="Already have an account? "
            linkName="Login"
            linkUrl="/"
          />
          <Signup
            fields={fields}
            signupState={signupState}
            onChange={handleChange}
            handleSubmit={handleSubmit}
            errors={errors}
            isLoading={isLoading}
            isChecked={checkbox}
            onChangeCheckbox={(isChecked) => setCheckbox(isChecked)}
          />
        </div>
      </div>
      <Footer />
      <ToastContainer />
    </>
  );
}
