import * as amaApi from "../api/amaApi";
import { useState } from "react";

export default function useVote(csrf) {
  const [votes, setVotes] = useState({});

  const onHandleVote = ({ id, type, oldVote, onSuccess, onFailure }) => {
    let voteType = type;
    let previousVote = oldVote;

    // Check if the user already voted on this question
    if (id in votes) {
      previousVote = votes[id];
    }

    if (previousVote === type) {
      voteType = 0;
    }

    setVotes({ ...votes, [id]: voteType });

    let textVoteType = "neutral";

    switch (voteType) {
      case 0:
        textVoteType = "neutral";
        break;
      case 1:
        textVoteType = "upvote";
        break;
      case -1:
        textVoteType = "downvote";
        break;
      default:
        break;
    }

    amaApi
      .submitVote(id, textVoteType, csrf)
      .then((response) => {
        if (onSuccess) {
          onSuccess(response.data);
        }
      })
      .catch(() => {
        // Revert the vote if the request fails
        if (previousVote) {
          setVotes({ ...votes, [id]: previousVote });
        } else {
          const newVotes = { ...votes };
          delete newVotes[id];
          setVotes(newVotes);
        }

        if (onFailure) {
          onFailure();
        }
      });
  };

  return { onHandleVote, votes };
}
