import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import HeroHome from "../components/HeroHome";

function LandingPage() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");

  const handleOnUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handleOnGetStarted = () => {
    navigate("/signup", { state: { username: username } });
  };

  const handleClickScroll = (position) => {
    const element = document.getElementById("section-" + position);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <main className="flex flex-col flex-1">
        <HeroHome
          username={username}
          onChange={handleOnUsernameChange}
          onClick={handleOnGetStarted}
          onClickScroll={handleClickScroll}
        />
      </main>
      <Footer />
    </>
  );
}

export default LandingPage;
