import api from "./axios";

export const getSession = () => {
  return api.get("/api/session/", {
    withCredentials: true,
  });
};

export const getUsername = () => {
  return api.get("/api/account/", {
    withCredentials: true,
  });
};

export const logout = (csrf) => {
  return api.request({
    url: "/api/logout/",
    method: "POST",
    headers: { "X-CSRFToken": csrf },
    withCredentials: true,
  });
};

export const login = (loginState, csrf) => {
  return api.post(
    "/api/login/",
    {
      username: loginState.username,
      password: loginState.password,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrf,
      },
      withCredentials: true,
    }
  );
};

export const signup = (signupState, csrf) => {
  return api.post(
    "/api/signup/",
    {
      username: signupState.username,
      email: signupState.email,
      password: signupState.password,
      password_check: signupState.confirmPassword,
      telnumber: signupState.telnumber,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrf,
      },
      withCredentials: true,
    }
  );
};

export const resetPassword = (email, csrf) => {
  return api.post(
    "/api/reset-password/",
    {
      email: email,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrf,
      },
      withCredentials: true,
    }
  );
};

export const setNewPassword = (formState, uidb64, token, csrf) => {
  return api.post(
    "/api/set-new-password/",
    {
      new_password: formState.new_password,
      new_password_check: formState.new_password_check,
      uidb64: uidb64,
      token: token,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrf,
      },
      withCredentials: true,
    }
  );
};

export const deleteAccount = (password, csrf) => {
  return api.delete("/api/account/delete/", {
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": csrf,
    },
    data: {
      password: password,
    },
    withCredentials: true,
  });
};