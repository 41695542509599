import { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { useAuth } from "../contexts/AuthContext";
import Footer from "../components/Footer";
import Settings from "../components/Settings";
import { useErrorToast } from "../hooks/useErrorToast";
import { useSuccessToast } from "../hooks/useSuccessToast";
import * as userApi from "../api/userApi";
import LoadingSpinner from "../components/LoadingSpinner";

const categories = [
  {
    value: "category1",
    name: "Profile Info",
  },
  {
    value: "category2",
    name: "General",
  },
];

export default function SettingsPage() {
  const { csrf } = useAuth();

  const showSuccessToast = useSuccessToast();
  const showErrorToast = useErrorToast();

  const [settings, setSettings] = useState();
  const [amas, setAmas] = useState([]);
  const [errors, setErrors] = useState({});
  const [account, setAccount] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSiteLoading, setIsSiteLoading] = useState(true);

  const settingHandlers = {
    //username: (value) => setUsername(value),
    show_all_amas_on_profile_page: (value) =>
      setSettings({
        ...settings,
        show_all_amas_on_profile_page: value === "false" ? false : true,
      }),
    profile_page_ama_uuid: (value) =>
      setSettings({
        ...settings,
        profile_page_ama_uuid: value,
      }),
  };

  useEffect(() => {
    userApi
      .getSettings()
      .then(([settingsResponse, amasResponse, accountResponse]) => {
        setAmas(amasResponse.data);
        setAccount(accountResponse.data);
        setSettings(settingsResponse.data);
      })
      .catch(() => {
        showErrorToast("Something went wrong, please try again!");
      })
      .finally(() => setIsSiteLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnChangeSettings = (event) => {
    const { name, value } = event.target;

    if (settingHandlers[name]) {
      settingHandlers[name](value);
    }
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const { id } = e.target;

    setIsLoading(true);

    if (id === "general-settings") {
      userApi
        .updateSettings(settings, csrf)
        .then(() => {
          showSuccessToast("Settings updated successfully!");
          setErrors({});
        })
        .catch((error) => {
          error.response.status >= 400 && error.response.status < 500
            ? setErrors(error.response.data)
            : showErrorToast("Something went wrong, please try again!");
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      {isSiteLoading ? (
        <div
          id="loading-spinner"
          className="flex flex-1 content-center items-center center-content self-center min-h-screen"
        >
          <LoadingSpinner />
        </div>
      ) : (
        <div className="flex flex-1 self-center min-h-screen flex-col pt-36 md:pt-56 2xl:pt-72 pb-20 max-w-5xl w-full px-8 sm:px-12">
          <Settings
            categories={categories}
            settings={settings}
            amas={amas}
            account={account}
            onChangeUserSettings={handleOnChangeSettings}
            onSubmit={handleOnSubmit}
            isLoading={isLoading}
            errors={errors}
          />
        </div>
      )}
      <Footer />
      <ToastContainer />
    </>
  );
}
