const loginFields = [
  {
    labelText: "Username or email",
    labelFor: "username",
    id: "username",
    name: "username",
    type: "username",
    autoComplete: "on",
    isRequired: true,
    placeholder: "Username or email",
    autofocus: true,
  },
  {
    labelText: "Password",
    labelFor: "password",
    id: "password",
    name: "password",
    type: "password",
    autoComplete: "current-password",
    isRequired: true,
    placeholder: "Password",
    isToggle: true,
  },
];

const signupFields = [
  {
    labelText: "Username",
    labelFor: "username",
    id: "username",
    name: "username",
    type: "text",
    autoComplete: "username",
    isRequired: true,
    placeholder: "Username",
    autofocus: true,
  },
  {
    labelText: "Email",
    labelFor: "email",
    id: "email",
    name: "email",
    type: "email",
    autoComplete: "email",
    isRequired: true,
    placeholder: "Email",
  },
  {
    labelText: "Password",
    labelFor: "password",
    id: "password",
    name: "password",
    type: "password",
    autoComplete: "new-password",
    isRequired: true,
    placeholder: "Password",
    isToggle: true,
  },
  {
    labelText: "Confirm Password",
    labelFor: "confirmPassword",
    id: "confirmPassword",
    name: "confirmPassword",
    type: "password",
    autoComplete: "new-password",
    isRequired: true,
    placeholder: "Confirm Password",
    isToggle: true,
  },
];

const resetPasswordFields = [
  {
    labelText: "E-Mail",
    labelFor: "email",
    id: "email",
    name: "email",
    type: "email",
    autoComplete: "email",
    isRequired: true,
    placeholder: "E-Mail",
    autofocus: true,
  },
];

const setNewPasswordFields = [
  {
    labelText: "New password",
    labelFor: "newPassword",
    id: "new_password",
    name: "newPassword",
    type: "password",
    autoComplete: "new-password",
    isRequired: true,
    placeholder: "New password",
    autofocus: true,
    isToggle: true,
  },
  {
    labelText: "Confirm Password",
    labelFor: "confirmPassword",
    id: "new_password_check",
    name: "confirmPassword",
    type: "password",
    autoComplete: "new-password",
    isRequired: true,
    placeholder: "Confirm Password",
    isToggle: true,
  },
];

const deleteAccountFields = {
  labelText: "Password",
  labelFor: "password",
  id: "password",
  name: "password",
  type: "password",
  autoComplete: "current-password",
  isRequired: true,
  placeholder: "Your password",
};

export {
  loginFields,
  signupFields,
  resetPasswordFields,
  setNewPasswordFields,
  deleteAccountFields,
};
