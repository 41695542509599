export default function VoteIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 10 6.5"
      className={`${props.className}`}
    >
      <g transform="translate(-346 -393.5)">
        <path
          d="M4.232.922a1,1,0,0,1,1.536,0L8.633,4.36A1,1,0,0,1,7.865,6H2.135a1,1,0,0,1-.768-1.64Z"
          transform="translate(356 400) rotate(180)"
          className={props.styleFill}
          fill="rgba(255,255,255,0.64)"
        />
        <path
          d="M5,.562a1,1,0,0,0-.768.36L1.367,4.36A1,1,0,0,0,2.135,6h5.73a1,1,0,0,0,.768-1.64L5.768.922A1,1,0,0,0,5,.562m0-.5A1.5,1.5,0,0,1,6.152.6L9.017,4.04A1.5,1.5,0,0,1,7.865,6.5H2.135A1.5,1.5,0,0,1,.983,4.04L3.848.6A1.5,1.5,0,0,1,5,.062Z"
          transform="translate(356 400) rotate(180)"
          className={props.styleBorder}
          fill="#00000"
        />
      </g>
    </svg>
  );
}
