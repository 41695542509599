import { useState } from "react";
import ModalBody from "./ModalBody";
import ModalInput from "./ModalInput";

export default function SetNewEmailModal({
  errors,
  isLoading,
  onClickClose,
  onClickSubmit,
}) {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newEmail, setNewEmail] = useState("");

  return (
    <ModalBody
      errors={errors}
      title="Set new email address"
      buttonText="Set new email"
      loadingText="Setting email..."
      isLoading={isLoading}
      onClickClose={onClickClose}
      onClickSubmit={(e) => {
        e.preventDefault();
        onClickSubmit(currentPassword, newEmail);
      }}
    >
      <ModalInput
        id="password"
        type="password"
        label="Enter your password"
        placeholder="Enter password..."
        autoComplete="current-password"
        autoFocus={true}
        onChange={(e) => {
          setCurrentPassword(e.target.value);
        }}
        value={currentPassword}
        isToggle={true}
        errors={errors}
      />
      <ModalInput
        id="new_email"
        type="email"
        label="Enter new email"
        placeholder="Enter new email..."
        autoComplete="email"
        onChange={(e) => {
          setNewEmail(e.target.value);
        }}
        value={newEmail}
        errors={errors}
      />
    </ModalBody>
  );
}
