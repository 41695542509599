import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import DropdownItem from "./DropdownItem";
import classNames from "classnames";

export default function Dropdown({ sortByList, onClickSort, className }) {
  const sortByItems = sortByList.map((sortBy, index) => {
    let className = "";
    if (index === 0) {
      className = "rounded-t-xl";
    } else if (index === sortByList.length - 1) {
      className = "rounded-b-xl";
    }
    return (
      <DropdownItem
        key={sortBy.id}
        id={sortBy.id}
        name={sortBy.name}
        onClickSort={onClickSort}
        className={className}
      />
    );
  });

  return (
    <Menu
      as="div"
      className={classNames(className, "relative inline-block text-left")}
    >
      <div>
        <Menu.Button className="inline-flex w-full justify-center rounded-full border bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
          Sort by
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-full origin-top-right rounded-xl bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="">{sortByItems}</div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
