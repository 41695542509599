export default function ModalSelect({
  onChange,
  value,
  options,
  id,
  name,
  label,
}) {
  return (
    <div>
      <label htmlFor={id} className="block mb-2 text-sm font-medium text-gray-900">
        {label}
      </label>
      <select
        id={id}
        onChange={onChange}
        value={value}
        className="w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block pr-4 p-1 cursor-pointer"
      >
        {options.map((option) => (
          <option key={option.id} value={option.value}>
            {option.text}
          </option>
        ))}
      </select>
    </div>
  );
}
