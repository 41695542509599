import { useEffect, useState } from "react";
import PrivacyPolicySectionHeading from "../components/PrivacyPolicy/PrivacyPolicySectionHeading";

export function useTexts(root) {
  const [texts, setTexts] = useState([]);

  useEffect(() => {
    const collectTexts = (component) => {
      const texts = component
        .flatMap(({ props: { children } }) => children)
        .filter(({ type }) => type === PrivacyPolicySectionHeading)
        .map(({ props: { text } }, index) => `${index + 1}. ${text}`);

      setTexts(texts);
    };

    collectTexts(root);
  }, [root]);

  return texts;
}
