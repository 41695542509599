import { useState } from "react";
import * as authApi from "../api/authApi";
import * as userApi from "../api/userApi";
import { deleteAccountFields } from "../constants/formFields";
import { useAuth } from "../contexts/AuthContext";
import DeleteModalWithInput from "./DeleteModalWithInput";
import { useErrorToast } from "../hooks/useErrorToast";
import SetNewPasswordModal from "./SetNewPasswordModal";
import SetNewEmailModal from "./SetNewEmailModal";
import { useSuccessToast } from "../hooks/useSuccessToast";

export default function ProfileSettings(props) {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSetNewPasswordModal, setShowSetNewPasswordModal] = useState(false);
  const [showSetNewEmailModal, setShowSetNewEmailModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);

  const { showErrorToast } = useErrorToast();
  const showSuccessToast = useSuccessToast();
  const { csrf, getSession } = useAuth();

  const fields = deleteAccountFields;

  const handleDelete = (password) => {
    setIsLoading(true);

    authApi
      .deleteAccount(password, csrf)
      .then(() => {
        getSession();
        setErrors({});
      })
      .catch((error) => {
        error.response.status >= 400 && error.response.status < 500
          ? setErrors(error.response.data)
          : showErrorToast("Something went wrong, please try again!");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSetNewEmail = (password, newEmail) => {
    setIsModalLoading(true);

    userApi
      .setNewEmail(password, newEmail, csrf)
      .then(() => {
        setShowSetNewEmailModal(false);
        showSuccessToast("Successfully changed email!");
      })
      .catch((error) => {
        error.response.status &&
        error.response.status >= 400 &&
        error.response.status < 500
          ? setErrors(error.response.data)
          : showErrorToast("Something went wrong, please try again!");
      })
      .finally(() => {
        setIsModalLoading(false);
      });
  };

  const handleSetNewPassword = (
    currentPassword,
    newPassword,
    confirmNewPassword
  ) => {
    setIsModalLoading(true);

    userApi
      .setNewPassword(currentPassword, newPassword, confirmNewPassword, csrf)
      .then(() => {
        setShowSetNewPasswordModal(false);
        showSuccessToast("Successfully changed password!");
      })
      .catch((error) => {
        error.response.status >= 400 && error.response.status < 500
          ? setErrors(error.response.data)
          : showErrorToast("Something went wrong, please try again!");
      })
      .finally(() => {
        setIsModalLoading(false);
      });
  };

  return (
    <>
      {showSetNewPasswordModal && (
        <SetNewPasswordModal
          errors={errors}
          isLoading={isModalLoading}
          onClickClose={() => setShowSetNewPasswordModal(false)}
          onClickSubmit={handleSetNewPassword}
        />
      )}
      {showSetNewEmailModal && (
        <SetNewEmailModal
          errors={errors}
          isLoading={isModalLoading}
          onClickClose={() => setShowSetNewEmailModal(false)}
          onClickSubmit={handleSetNewEmail}
        />
      )}
      {showDeleteModal && (
        <DeleteModalWithInput
          title="Delete Account"
          deleteButton="Delete Account"
          onClickClose={() => setShowDeleteModal(false)}
          onClickDelete={handleDelete}
          inputDescription="Please enter your password to delete your account."
          placeholder={fields.placeholder}
          id={fields.id}
          type={fields.type}
          labelFor={fields.labelFor}
          errors={errors}
          isLoading={isLoading}
          isRequired={fields.isRequired}
        />
      )}
      <div
        id="user-settings"
        className="w-full mx-auto flex overflow-hidden bg-white rounded-3xl border mt-8"
      >
        <div className="w-1/3 border-r p-8 md:p-12 hidden md:inline-block">
          <h2 className="font-medium text-xl mb-4 tracking-wide">
            Profile Info
          </h2>
          <p className="text-xs">
            Update your profile information such as Email Address and Password.
          </p>
        </div>
        <div className="flex flex-col md:w-2/3 w-full p-8 sm:p-12">
          <div className="pb-8">
            <label htmlFor="name" className="text-sm">
              Username
            </label>
            <input
              className="mt-2 border px-4 py-2 block w-full rounded-full text-base focus:outline-none focus:border-indigo-500"
              type="text"
              defaultValue={props.account.username}
              placeholder="Your name"
              name="username"
              required
              disabled
            />
          </div>
          <div className="mb-8 h-full">
            <label htmlFor="email" className="text-sm ">
              Email Address
            </label>
            <div className="flex flex-col sm:flex-row mt-2">
              <input
                className="border px-4 h-12 w-full py-2 block rounded-full text-base focus:outline-none focus:border-indigo-500s"
                type="email"
                name="email"
                placeholder="Your email"
                defaultValue={props.account.email}
                required
                disabled
              />
              <div className="sm:ml-4 mt-4 sm:mt-0">
                <button
                  id="button-change-email"
                  onClick={() => {
                    setShowSetNewEmailModal(true);
                  }}
                  className="whitespace-nowrap h-full py-3 sm:py-0 w-full bg-purple-500 text-white md:hover:bg-purple-600 text-sm self-center sm:self-end font-medium px-8 md:ml-auto rounded-full cursor-pointer"
                >
                  Change Email
                </button>
              </div>
            </div>
          </div>
          <div className="mb-8 h-full">
            <label htmlFor="email" className="text-sm ">
              Password
            </label>
            <div className="flex flex-col sm:flex-row mt-2">
              <div className="sm:mt-0">
                <button
                  id="button-show-password"
                  onClick={() => {
                    setShowSetNewPasswordModal(true);
                  }}
                  className="whitespace-nowrap h-full py-3 w-full bg-purple-500 text-white md:hover:bg-purple-600 text-sm self-center sm:self-end font-medium px-8 md:ml-auto rounded-full cursor-pointer"
                >
                  Change Password
                </button>
              </div>
            </div>
          </div>
          <button
            id="button-delete-account"
            onClick={() => setShowDeleteModal(true)}
            className="text-red-500 bg-red-50 hover:bg-red-500 hover:text-white text-sm w-fit self-center sm:self-end font-medium mt-8 sm:mt-32 px-8 py-3 md:ml-auto rounded-full cursor-pointer"
          >
            Delete Account
          </button>
        </div>
      </div>
    </>
  );
}
