import { useState } from "react";
import ModalBody from "./ModalBody";
import ModalSelect from "./ModalSelect";
import ModalTextArea from "./ModalTextArea";

export default function ReportModal({
  content,
  errors,
  onClickClose,
  onClickSendReport,
  isLoading,
}) {
  const [select, setSelect] = useState(
    content.options ? content.options[0].value : ""
  );
  const [description, setDescription] = useState("");

  return (
    <ModalBody
      title={content.title}
      onClickClose={onClickClose}
      onClickSubmit={(e) => {
        e.preventDefault();
        onClickSendReport({
          specific: select,
          reason: description,
          value: content.value,
        });
      }}
      isLoading={isLoading}
      loadingText={content.loadingText}
      buttonText={content.buttonText}
      errors={errors}
    >
      {content.options && (
        <ModalSelect
          onChange={(e) => {
            setSelect(e.target.value);
          }}
          value={select}
          options={content.options}
          id={content.selectID}
          label={content.selectLabel}
        />
      )}
      <ModalTextArea
        description={content.inputDescription}
        value={description}
        onChange={(e) => {
          setDescription(e.target.value);
        }}
        errors={errors}
        placeholder={content.inputPlaceholder}
        name={content.inputName}
      />
    </ModalBody>
  );
}
