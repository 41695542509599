import { Menu } from "@headlessui/react";
import classNames from "classnames";

export default function DropdownItem(props) {
  return (
    <Menu.Item>
      {({ active }) => (
        <button
          onClick={() => props.onClickSort(props.id)}
          className={classNames(
            active ? "bg-gray-100 text-gray-900" : "text-gray-700",
            "block px-4 py-2 text-sm w-full text-start", props.className
          )}
        >
          {props.name}
        </button>
      )}
    </Menu.Item>
  );
}
