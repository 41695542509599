import { useRef, useState } from "react";
import Title from "./Title";
import Questions from "./Questions";
import Dropdown from "./Dropdown";
import { useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import { useErrorToast } from "../hooks/useErrorToast";
import { useSuccessToast } from "../hooks/useSuccessToast";
import LoadingSpinner from "./LoadingSpinner";
import * as amaApi from "../api/amaApi";
import MoreButton from "./MoreButton";
import ReportModal from "./Modal/ReportModal";
import { reportFields } from "../constants/reportFields";
import { optionFields } from "../constants/optionFields";
import { Link } from "react-router-dom";
import { handleSendReport } from "../utils/reportUtils";
import classNames from "classnames";
import Tabs from "./Tabs";
import AskQuestionInputField from "./AskQuestionInputField";
import Fuse from "fuse.js";
import useVote from "../hooks/useVote";

export default function Ama({ uuid }) {
  const [tabs, setTabs] = useState([
    {
      id: 0,
      name: "answered",
      active: true,
      noQuestionsText: "No unanswered questions yet",
    },
    {
      id: 1,
      name: "open",
      active: true,
      noQuestionsText: "No answered questions yet",
    },
    { id: 2, name: "filtered", active: false, noQuestionsText: "" },
  ]);
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [previousTab, setPreviousTab] = useState(null);
  const [showSimilarQuestionWarning, setShowSimilarQuestionWarning] =
    useState(false);
  const [hasLowScoreResult, setHasLowScoreResult] = useState(null);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [showQuestionInput, setShowQuestionInput] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [unansweredQuestions, setUnansweredQuestions] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [ama, setAma] = useState([]);
  const [askQuestion, setAskQuestion] = useState("");
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState({
    questions: true,
    submit: false,
    report: false,
  });
  const [report, setReport] = useState(null);
  const [selectedQuestionAnswer, setSelectedQuestionAnswer] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);

  const showSuccessToast = useSuccessToast();
  const showErrorToast = useErrorToast();
  const { csrf, isAuthenticated } = useAuth();
  const { onHandleVote, votes } = useVote(csrf);

  const inputRef = useRef(null);
  const textAreaRef = useRef(null);

  const fuseOptions = {
    includeScore: true,
    keys: ["question"],
  };

  const changeTab = (newTab) => {
    if (activeTab.id !== newTab.id) {
      setPreviousTab(activeTab);
      setActiveTab(newTab);

      if (newTab.id === 2) {
        setShowQuestionInput(true);
      }
    }
  };

  useEffect(() => {
    const updateTabActive = (tabId, newActiveValue, newTab) => {
      const updatedTabs = tabs.map((tab) =>
        tab.id === tabId ? { ...tab, active: newActiveValue } : tab
      );

      changeTab(updatedTabs[newTab]);
      setTabs(updatedTabs);
    };

    if (searchResults.length > 0) {
      updateTabActive(2, true, 2);
    }

    setShowSimilarQuestionWarning(false);
  }, [searchResults]);

  const fuse = new Fuse([...questions, ...unansweredQuestions], fuseOptions);

  const handleSearch = (query) => {
    const results = fuse.search(query);
    setSearchResults(results.map((result) => result.item));

    if (results?.some((result) => result.item.question === query) || false) {
      setErrors({
        question:
          "This question has already been asked. Consider upvoting it down below to avoid duplicates and help make it more visible.",
      });
      setIsDuplicate(true);
    } else {
      setErrors({});
      setIsDuplicate(false);
    }

    const hasLowScoreResult = results.some((result) => result.score < 0.12);
    setHasLowScoreResult(hasLowScoreResult);
  };

  const scrollToInputField = () => {
    if (inputRef.current) {
      textAreaRef.current.focus();

      const header = document.querySelector("header");
      const headerRect = header.getBoundingClientRect();
      const margin = 20; // Define your desired margin
      const scrollPosition =
        inputRef.current.offsetTop - (headerRect.bottom + margin);

      window.scrollTo({
        top: scrollPosition,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (showQuestionInput) {
      scrollToInputField();
    }
  }, [showQuestionInput]);

  const handleAskQuestionClick = () => {
    fetchUnansweredQuestions();
    if (searchResults.length !== 0 && tabs[2].active === true) {
      changeTab(tabs[2]);
    }
    setShowQuestionInput(true);
    scrollToInputField();
  };

  const handleOnClickOption = (e, uuid) => {
    if (e.target.value === "copy-link") {
      navigator.clipboard
        .writeText(process.env.REACT_APP_BASE_URL + "/question/" + uuid)
        .then(() => {
          showSuccessToast("Copied to clipboard", "copied");
        });
    } else {
      setSelectedQuestionAnswer(uuid);
      setReport(e.target.value);
    }
  };

  const fetchQuestions = () => {
    amaApi
      .getQuestions(uuid, true, isAuthenticated)
      .then(([questionsResponse, accountResponse]) => {
        const questionsList = questionsResponse.data.questionanswer_set;
        accountResponse && setCurrentUser(accountResponse.data.username);
        setQuestions(sortByVotes(questionsList, 0));
        setAma(questionsResponse.data);
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading({ ...isLoading, questions: false });
      });
  };

  const handleClickSubmit = (e) => {
    e.preventDefault();
    if (hasLowScoreResult && !showSimilarQuestionWarning) {
      setShowSimilarQuestionWarning(true);
    } else {
      submitQuestion();
    }
  };

  const submitQuestion = () => {
    setIsLoading({ ...isLoading, submit: true });

    amaApi
      .submitQuestion(askQuestion, ama.uuid, csrf)
      .then((response) => {
        const data = response.data;
        showSuccessToast("Your question has been submitted!");
        setErrors({});
        addNewQuestion(data);
        setShowQuestionInput(false);
        const updatedTabs = tabs.map((tab) =>
          tab.id === 2 ? { ...tab, active: false } : tab
        );

        setTabs(updatedTabs);
        setSearchResults([]);
        changeTab(previousTab);
        setAskQuestion("");
        setShowSimilarQuestionWarning(false);
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.status >= 400 &&
          error.response.status < 500
        ) {
          setErrors(error.response.data);
        } else {
          showErrorToast("Something went wrong, please try again!");
        }
      })
      .finally(() => {
        setIsLoading({ ...isLoading, submit: false });
      });
  };

  const addNewQuestion = (newQuestion) => {
    // Create a copy of the current unansweredQuestions state and add the newQuestion
    const updatedUnansweredQuestions = [...unansweredQuestions];
    updatedUnansweredQuestions.push(newQuestion);

    // Update the state with the new array
    setUnansweredQuestions(updatedUnansweredQuestions);
  };

  const onClickVote = (id, type, oldVote) => {
    onHandleVote({
      id: id,
      type: type,
      oldVote: oldVote,
      onSuccess: (data) => {
        // Check which array contains the question
        const isQuestionInQuestions = questions.some(
          (question) => question.uuid === id
        );
        const isQuestionInUnansweredQuestions = unansweredQuestions.some(
          (question) => question.uuid === id
        );

        // Update the votes of the question
        if (isQuestionInQuestions) {
          const newQuestions = questions.map((question) => {
            if (question.uuid === id) {
              return { ...question, votes: data.votes };
            }
            return question;
          });
          setQuestions(newQuestions);
        } else if (isQuestionInUnansweredQuestions) {
          const newUnansweredQuestions = unansweredQuestions.map((question) => {
            if (question.uuid === id) {
              return { ...question, votes: data.votes };
            }
            return question;
          });
          setUnansweredQuestions(newUnansweredQuestions);
        }
      },
      onFailure: () => {
        showErrorToast("Something went wrong, please try again!");
      },
    });
  };

  const onClickSort = (sortBy) => {
    const sortArrayByCriteria = (array, sortBy) => {
      switch (sortBy) {
        case 0:
        case 1:
          return sortByVotes([...array], sortBy);
        case 2:
        case 3:
          return sortByDate([...array], sortBy);
        default:
          return [...array];
      }
    };

    setQuestions(() => sortArrayByCriteria(questions, sortBy));
    setUnansweredQuestions(() =>
      sortArrayByCriteria(unansweredQuestions, sortBy)
    );
  };

  const sortByVotes = (array, type) => {
    return array.sort((a, b) => {
      const aVotes = a.votes + a.voted;
      const bVotes = b.votes + b.voted;

      if (type === 0) {
        return bVotes - aVotes;
      } else {
        return aVotes - bVotes;
      }
    });
  };

  const sortByDate = (array, type) => {
    const newList = array.map((obj) => {
      return {
        ...obj,
        dateToSort: obj.hasOwnProperty("answered_at")
          ? new Date(obj.answered_at)
          : new Date(obj.asked_at),
      };
    });

    return newList.sort((a, b) =>
      type === 2 ? b.dateToSort - a.dateToSort : a.dateToSort - b.dateToSort
    );
  };

  const handleTabClick = (tab) => {
    if (tab.id === 0) {
      if (questions.length === 0) {
        fetchQuestions();
      }
    } else {
      if (unansweredQuestions.length === 0) {
        fetchUnansweredQuestions();
      }
    }

    changeTab(tab);
  };

  const fetchUnansweredQuestions = () => {
    setIsLoading({ ...isLoading, questions: true });

    amaApi
      .getUnansweredQuestions(uuid)
      .then((response) => {
        const questionsList = response.data.questionanswer_set;
        setUnansweredQuestions(sortByVotes(questionsList, 0));
      })
      .catch(() => {
        showErrorToast("Something went wrong, please try again!");
      })
      .finally(() => {
        setIsLoading({ ...isLoading, questions: false });
      });
  };

  const getQuestionsForTab = (tab) => {
    switch (tab) {
      case 0:
        return questions;
      case 1:
        return unansweredQuestions;
      case 2:
        return searchResults;
      default:
        return questions;
    }
  };

  const getActiveTabs = () => {
    return tabs.filter((tab) => tab.active);
  };

  useEffect(() => {
    fetchQuestions();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {report && (
        <ReportModal
          content={reportFields.find((field) => field.value === report)}
          errors={errors}
          onClickClose={() => {
            setReport(null);
          }}
          onClickSendReport={(report) => {
            handleSendReport(
              report,
              selectedQuestionAnswer,
              ama,
              isLoading,
              setIsLoading,
              setErrors,
              setReport,
              setSelectedQuestionAnswer,
              csrf,
              showSuccessToast,
              showErrorToast
            );
          }}
          isLoading={isLoading.report}
        />
      )}

      <div className="flex flex-1 content-center flex-col center-content self-center max-w-4xl min-h-screen pt-36 md:pt-56 2xl:pt-72 pb-20 w-full px-6 md:px-10">
        {ama.title ? (
          <>
            <div className="mb-10">
              <div className="grid sm:gap-0 grid-cols-[auto_fit-content] grid-rows-[auto_1fr]">
                <div className="sm:col-span-2 flex h-fit flex-col">
                  <Title title={ama.title} />
                  <Link
                    reloadDocument={true}
                    to={`/${ama.username}`}
                    className="text-purple-500 hover:text-purple-600 w-fit mt-1 text-lg font-medium"
                  >
                    {ama.username}
                  </Link>
                </div>
                {/* Checks if AMA belongs to current logged in user, so user can't ask himself questions */}
                {currentUser !== ama.username && (
                  <div className="flex flex-row-reverse w-full sm:w-fit sm:flex-row mr-auto sm:mr-0 sm:ml-auto row-start-3 sm:row-start-1 sm:row-end-1 sm:col-start-3 items-center sm:items-start mt-4 sm:mt-0">
                    <MoreButton
                      buttonID="options-user-ama"
                      options={optionFields[0]}
                      onClick={handleOnClickOption}
                      className="h-12 ml-2 sm:mr-2 bg-w rounded-xl"
                    />
                    <button
                      id="btn-ask-question"
                      className="rounded-full p-3 px-6 min-w-max w-full bg-purple-500 hover:bg-purple-700 text-white font-medium sm:w-fit h-fit"
                      onClick={handleAskQuestionClick}
                    >
                      Ask a question
                    </button>
                  </div>
                )}
                <div
                  className={classNames(
                    !ama.description && "hidden",
                    "sm:col-span-2 mt-6"
                  )}
                >
                  <h2 className="font-semibold sm:text-lg mb-2">Description</h2>
                  <p className="text-sm sm:text-md whitespace-pre-wrap">
                    {ama.description ? ama.description : "No description"}
                  </p>
                </div>
              </div>
            </div>
            <div className="mb-4 sm:mb-6 grid grid-cols-2 grid-rows-2 sm:grid-rows-1 sm:grid-cols-3">
              <Title title={"Questions"} />
              <Tabs
                className="w-full sm:w-fit justify-self-center sm:col-span-1 col-span-2"
                tabs={getActiveTabs()}
                activeTab={activeTab}
                onTabClick={handleTabClick}
              />
              <Dropdown
                className="w-fit ml-auto sm:col-start-3 row-start-1 col-start-2"
                sortByList={[
                  { name: "Highest", id: 0 },
                  { name: "Lowest", id: 1 },
                  { name: "Newest", id: 2 },
                  { name: "Oldest", id: 3 },
                ]}
                onClickSort={onClickSort}
              />
            </div>
            {showQuestionInput && (
              <div>
                <AskQuestionInputField
                  inputRef={inputRef}
                  textAreaRef={textAreaRef}
                  className="mb-4"
                  handleChange={(event) => {
                    handleSearch(event.target.value);
                    setAskQuestion(event.target.value);
                  }}
                  text={askQuestion}
                  onClickSubmit={handleClickSubmit}
                  isLoading={isLoading.submit}
                  buttonText="Submit question"
                  loadingText="Submitting question..."
                  onClickClose={() => {
                    setShowQuestionInput(false);
                    if (activeTab.id === 2) {
                      setActiveTab(previousTab);
                    }
                  }}
                  warning={
                    showSimilarQuestionWarning &&
                    "We might have found a question that is similar to yours. Take a look below and consider upvoting to avoid duplicates and help make it more visible!"
                  }
                  errors={errors}
                  isDuplicate={isDuplicate}
                />
                {searchResults.length > 0 && activeTab.id === 2 && (
                  <p className="text-2xl font-semibold mb-4 mt-8 ml-1">
                    Similar questions:
                  </p>
                )}
              </div>
            )}
            {isLoading.questions ? (
              <div
                id="loading-spinner"
                className="flex flex-1 content-center items-center center-content self-center"
              >
                <LoadingSpinner />
              </div>
            ) : (
              <Questions
                amaId={ama.uuid}
                votes={votes}
                onClickOption={handleOnClickOption}
                options={activeTab.id === 0 ? optionFields[1] : optionFields[2]}
                onClickVote={onClickVote}
                questions={getQuestionsForTab(activeTab.id)}
                noQuestionsText={activeTab.noQuestionsText}
              />
            )}
          </>
        ) : (
          <p className="self-center justify-center">
            Sorry, this AMA does not exist.
          </p>
        )}
      </div>
    </>
  );
}
