import classnames from "classnames";

export default function HoneypotInput({
  handleChange,
  value,
  labelText,
  labelFor,
  id,
  name,
  type,
  isRequired = false,
  className,
}) {
  return (
    <div className="!m-0">
      <label htmlFor={labelFor} className="sr-only">
        {labelText}
      </label>
      <input
        onChange={handleChange}
        value={value}
        id={id}
        name={name}
        type={type}
        required={isRequired}
        className={classnames(
          className,
          "opacity-0 absolute top-0 left-0 h-0 w-0 z-0"
        )}
        tabIndex="-1"
        autoComplete="off"
      />
    </div>
  );
}
