import { useState } from "react";
import { Link } from "react-router-dom";
import { resetPasswordFields } from "../constants/formFields";
import FormAction from "./FormAction";
import Input from "./Input";
import * as authApi from "../api/authApi";
import { useAuth } from "../contexts/AuthContext";
import { useErrorToast } from "../hooks/useErrorToast";

const fields = resetPasswordFields;
let fieldsState = {};
fields.forEach((field) => (fieldsState[field.id] = ""));

export default function PasswordReset(props) {
  const { csrf } = useAuth();
  const [loginState, setLoginState] = useState(fieldsState);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const showErrorToast = useErrorToast();

  const handleChange = (e) => {
    setLoginState({ ...loginState, [e.target.id]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    resetPassword();
  };

  const resetPassword = () => {
    setIsLoading(true);
    authApi
      .resetPassword(loginState.email, csrf)
      .then(() => {
        props.setSubmitted(true);
        setErrors({});
      })
      .catch((error) => {
        if (error.response.status >= 400 && error.response.status < 500) {
          setErrors(error.response.data);
        } else {
          showErrorToast("Something went wrong, please try again!");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="-space-y-px">
          {fields.map((field) => (
            <Input
              key={field.id}
              handleChange={handleChange}
              value={loginState[field.id]}
              labelText={field.labelText}
              labelFor={field.labelFor}
              id={field.id}
              name={field.name}
              type={field.type}
              isRequired={field.isRequired}
              placeholder={field.placeholder}
              error={errors[field.id]}
              autofocus={field.autofocus}
              autoComplete={field.autoComplete}
            />
          ))}
        </div>
        <FormAction
          type="submit"
          text={props.submitted ? "Password reset requested" : "Reset password"}
          isLoading={isLoading}
        />
      </form>

      <p className="text-center text-sm text-gray-600 mt-5">
        <Link
          to="/login"
          className="font-medium text-purple-600 hover:text-purple-500"
        >
          Back to login
        </Link>
      </p>
    </>
  );
}
