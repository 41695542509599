import FAQ from "../components/FAQ";
import Footer from "../components/Footer";
import { faqQuestions } from "../constants/faqQuestions";

export default function FaqPage() {
  return (
    <>
      <FAQ questions={faqQuestions} />
      <Footer />
    </>
  );
}
