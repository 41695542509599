import HeaderLogin from "../components/HeaderLogin";
import Login from "../components/Login";
import { useState } from "react";
import { loginFields } from "../constants/formFields";
import { useAuth } from "../contexts/AuthContext";
import Footer from "../components/Footer";
import * as authApi from "../api/authApi";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function LoginPage() {
  const fields = loginFields;
  let fieldsState = {};
  fields.forEach((field) => (fieldsState[field.id] = ""));

  const { csrf, setIsAuthenticated } = useAuth();
  const [errors, setErrors] = useState({});
  const [loginState, setLoginState] = useState(fieldsState);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const login = () => {
    setIsLoading(true);

    authApi
      .login(loginState, csrf)
      .then(() => {
        setIsAuthenticated(true);
        navigate("/my-amas", { replace: true });
        setErrors({});
      })
      .catch((error) => {
        if (error.response.status >= 400 && error.response.status < 500) {
          setErrors(error.response.data);
        } else {
          toast.error("Something went wrong, please try again!", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleChange = (e) => {
    e.preventDefault();
    setLoginState({ ...loginState, [e.target.id]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (loginState.username !== "" && loginState.password !== "") {
      login();
    }
  };

  return (
    <>
      <div className="flex flex-1 content-center justify-center min-h-screen h-fit flex-col center-content self-center max-w-lg w-full py-40 px-6 sm:px-0">
        <HeaderLogin heading="Login to your account" />
        <Login
          fields={fields}
          loginState={loginState}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          errors={errors}
          isLoading={isLoading}
        />
      </div>
      <Footer />
      <ToastContainer />
    </>
  );
}
