import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import Search from "./Search";
import {
  Bars3Icon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import { useAuth } from "../contexts/AuthContext";
import { ReactComponent as Logo } from "../assets/askmee-logo.svg";
import classNames from "classnames";

export default function Header() {
  const { isAuthenticated, logout } = useAuth();
  const location = useLocation();
  const specificPages = ["/", "/privacy-policy", "settings"];
  const isSpecificPage = specificPages.includes(location.pathname);

  const [navBarVisible, setNavBarVisible] = useState(false);

  const navigate = useNavigate();

  const btnStyle =
    "group hidden xm:flex items-center h-full bg-purple-500 text-white text-sm font-medium px-6 py-2 cursor-pointer shadow-sm hover:bg-purple-600 rounded-full flex items-center";

  return (
    <header className="fixed flex flex-col rounded-full gridcontainer max-w-4xl w-full xl:max-w-5/6 2xl:max-w-90 z-30 mt-6 md:mt-10 place-self-center px-6 md:px-10 items-center justify-between">
      <div className="bg-white flex rounded-full w-full place-self-center p-3 border items-center justify-between h-[4.5rem] md:h-20">
        <div
          className={classNames(
            isSpecificPage ? "bg-transparent" : "bg-purple-50",
            "h-[3.75rem] md:h-[5rem] top-0 right-0 left-0 w-full fixed -z-10"
          )}
        />
        <Link
          to="/"
          className="ml-3 h-full aspect-square flex"
          aria-label="Home"
        >
          <Logo
            alt="Logo"
            className="h-[95%] self-center hover:rotate-[360deg] transition duration-300 ease-in-out text-purple-500"
          />
        </Link>

        <div className="hidden sm:flex items-center ml-10 font-medium text-gray-600 px-5 py-3 space-x-6">
          {!isAuthenticated && (
            <Link to="/" className="hover:text-purple-500">
              Home
            </Link>
          )}
          <Link to="/featured" className="hover:text-purple-500">
            Featured
          </Link>
          {isAuthenticated && (
            <Link to="/my-amas" className="hover:text-purple-500">
              My AMAs
            </Link>
          )}
          <Link to="/help" className="hover:text-purple-500">
            Help
          </Link>
          {isAuthenticated && (
            <Link to="/settings" className="hover:text-purple-500">
              Settings
            </Link>
          )}
        </div>

        <nav className="flex flex-grow h-full">
          <ul className="flex flex-grow justify-end flex-wrap items-center">
            <li className="hidden xl:block h-full">
              <Search id="search-field-header" />
            </li>
            {!isAuthenticated && (
              <li>
                <Link
                  to="/login"
                  className="font-medium text-gray-600 hover:text-gray-900 px-5 hidden mm:flex items-center"
                >
                  Login
                </Link>
              </li>
            )}
            <li className="h-full">
              {!isAuthenticated ? (
                <Link to="/signup" className={`${btnStyle}`}>
                  Sign up
                  <svg
                    aria-hidden="true"
                    className="ml-1.5 -mr-1 h-4"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </Link>
              ) : (
                <button
                  onClick={logout}
                  className={`${isAuthenticated && "ml-6"} ${btnStyle}`}
                >
                  Logout
                </button>
              )}
            </li>
            <li
              id="open-menu-button"
              onClick={() => {
                setNavBarVisible(!navBarVisible);
              }}
              className="aspect-square xl:hidden h-full p-[0.4rem] md:p-3 rounded-full ml-2 text-slate-600 bg-slate-100 hover:bg-slate-200 hover:text-slate-900 cursor-pointer"
            >
              {navBarVisible ? (
                <XMarkIcon className="h-full" />
              ) : (
                <>
                  <Bars3Icon className="md:hidden h-full" />
                  <MagnifyingGlassIcon className="hidden md:block h-full" />
                </>
              )}
            </li>
          </ul>
        </nav>
      </div>

      {navBarVisible && (
        <div className="bg-white transition-opacity border p-3 rounded-[2rem] w-full mt-2 flex flex-col xl:hidden shadow-sm">
          <Search
            id="search-field-menu"
            twStyle="h-10"
            setNavBarVisible={setNavBarVisible}
          />
          <div className="h-3 sm:h-0" />
          <div className="flex flex-col sm:hidden font-medium border rounded-[1.25rem] w-full text-gray-600">
            {!isAuthenticated && (
              <Link
                to="/"
                onClick={() => setNavBarVisible(false)}
                className={classNames(
                  "hover:bg-purple-50 rounded-t-[1.25rem] py-[0.75rem] px-[0.75rem]",
                  !isAuthenticated && "border-b"
                )}
              >
                Home
              </Link>
            )}
            <Link
              to="/featured"
              onClick={() => setNavBarVisible(false)}
              className="hover:bg-purple-50 border-b py-[0.75rem] px-[0.75rem]"
            >
              Featured
            </Link>
            {isAuthenticated && (
              <>
                <Link
                  to="/my-amas"
                  onClick={() => setNavBarVisible(false)}
                  className={classNames(
                    "hover:bg-purple-50 py-[0.75rem] px-[0.75rem]",
                    isAuthenticated ? "border-b" : "border-y"
                  )}
                >
                  My AMAs
                </Link>
                <Link
                  to="/settings"
                  onClick={() => setNavBarVisible(false)}
                  className={classNames(
                    "hover:bg-purple-50 py-[0.75rem] px-[0.75rem]",
                    isAuthenticated ? "border-b" : "border-y"
                  )}
                >
                  Settings
                </Link>
              </>
            )}
            <Link
              to="/help"
              onClick={() => setNavBarVisible(false)}
              className="hover:bg-purple-50 rounded-b-[1.25rem] py-[0.75rem] px-[0.75rem]"
            >
              Help
            </Link>
          </div>
          {!isAuthenticated ? (
            <>
              <button
                onClick={() => {
                  setNavBarVisible(false);
                  navigate("/login", { replace: true });
                }}
                className="auto mm:hidden my-3 font-medium"
              >
                Log In
              </button>
              <button
                onClick={() => {
                  setNavBarVisible(false);
                  navigate("/signup", { replace: true });
                }}
                className="auto mm:hidden group items-center bg-purple-500 text-white text-center justify-center text-sm font-medium h-10 pl-6 pr-6 py-2 cursor-pointer shadow-sm hover:bg-purple-600 rounded-full"
              >
                Sign up
              </button>
            </>
          ) : (
            <button
              onClick={() => {
                setNavBarVisible(false);
                logout();
              }}
              className="auto mm:hidden group items-center bg-purple-500 text-white text-center justify-center text-sm font-medium h-10 pl-6 pr-6 py-2 mt-4 cursor-pointer shadow-sm hover:bg-purple-600 rounded-full"
            >
              Logout
            </button>
          )}
        </div>
      )}
    </header>
  );
}
