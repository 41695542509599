import { Link } from "react-router-dom";
import MyAmaStatsItem from "./MyAmaStatsItem";

export default function MyAmaItem(props) {
  const statsItemClass = "w-full sm:w-auto px-2 sm:px-4";

  const questionsAnsweredCount =
    props.questionsCount - props.questionsUnansweredCount;

  return (
    <li className="flex flex-col border bg-white p-4 xm:p-6 rounded-3xl mb-6">
      <div className="flex flex-col-reverse mm:flex-row mb-5">
        <div className="sxm:mr-6">
          <Link
            to={`/ama/${props.id}/`}
            className="text-lg font-medium break-all"
          >
            {props.title ? props.title : "No title"}
          </Link>
          <p
            data-testid={"date-created-" + props.id}
            aria-label="Date created"
            className="text-xs text-slate-400"
          >
            {new Date(props.dateCreated).toLocaleDateString("en-us", {
              weekday: "long",
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
            })}
          </p>
        </div>
        <div className="flex flex-row-reverse mm:flex-row mm:ml-auto items-center h-fit mb-4 sm:mb-0">
          <button
            onClick={props.onClickDelete}
            className="text-color-red-200 text-red-500 hover:text-red-700 font-medium"
          >
            Delete
          </button>
          <button
            aria-label="Edit"
            onClick={props.onClickEdit}
            className="p-3 border fill-purple-500 hover:fill-purple-700 hover:shadow rounded-xl h-fit w-fit mm:ml-4 mr-auto"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="18px"
              viewBox="0 0 24 24"
              width="18px"
              fill="current"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" />
            </svg>
          </button>
        </div>
      </div>
      <div className="flex flex-col sm:flex-row items-center">
        <div className="flex justify-between mm:justify-start w-full sm:w-auto">
          <MyAmaStatsItem
            twStyle={statsItemClass}
            count={props.questionsCount}
            text={"Questions"}
            dataTestId="stats-div-questions"
          />
          <div className="mr-3 xm:mr-4" />
          <MyAmaStatsItem
            twStyle={statsItemClass}
            count={questionsAnsweredCount}
            text={"Answered"}
            dataTestId="stats-div-answered"
          />
          <div className="mr-0 tiny:mr-3 xm:mr-4" />
          <MyAmaStatsItem
            twStyle={"hidden sxm:flex " + statsItemClass}
            count={props.questionsUnansweredCount}
            text={"Unanswered"}
            dataTestId="stats-div-unanswered"
          />
        </div>
        <Link
          id="btn-answer-questions"
          to={"/answer-questions/" + props.id}
          className="bg-purple-500 hover:bg-purple-700 rounded-full h-full sm:self-end p-3 px-6 w-full mm:w-fit ml-none mt-6 sm:mt-auto md:mt-0 sm:ml-auto text-white text-center font-medium"
        >
          Answer questions
        </Link>
      </div>
    </li>
  );
}
