import { useState } from "react";
import ModalBody from "./ModalBody";
import ModalInput from "./ModalInput";

export default function SetNewPasswordModal({
  errors,
  isLoading,
  onClickClose,
  onClickSubmit,
}) {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPAssword] = useState("");

  return (
    <ModalBody
      errors={errors}
      title="Set new password"
      buttonText="Set new password"
      loadingText="Setting password..."
      isLoading={isLoading}
      onClickClose={onClickClose}
      onClickSubmit={(e) => {
        e.preventDefault();
        onClickSubmit(currentPassword, newPassword, confirmNewPassword);
      }}
    >
      <ModalInput
        id="old_password"
        type="password"
        label="Enter current password"
        placeholder="Enter current password..."
        autoComplete="current-password"
        autoFocus={true}
        onChange={(e) => {
          setCurrentPassword(e.target.value);
        }}
        value={currentPassword}
        isToggle={true}
        errors={errors}
      />
      <ModalInput
        id="new_password"
        type="password"
        label="Enter new password"
        placeholder="Enter new password..."
        autoComplete="new-password"
        onChange={(e) => {
          setNewPassword(e.target.value);
        }}
        value={newPassword}
        isToggle={true}
        errors={errors}
      />
      <ModalInput
        id="new_password_check"
        type="password"
        label="Confirm new password"
        placeholder="Enter new password again..."
        autoComplete="new-password"
        onChange={(e) => {
          setConfirmNewPAssword(e.target.value);
        }}
        value={confirmNewPassword}
        isToggle={true}
        errors={errors}
      />
    </ModalBody>
  );
}
