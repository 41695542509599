export const reportFields = [
  {
    parent: "options-ama",
    value: "report-ama",
    title: "Report AMA",
    isLoading: false,
    loadingText: "Sending report...",
    buttonText: "Send report",
    selectLabel: "What do you want to report?",
    selectID: "report-subject",
    inputDescription: "Please enter a reason:",
    inputPlaceholder: "Enter a reason...",
    inputName: "report-ama",
    options: [
      { id: "title", value: "title", text: "Title" },
      { id: "description", value: "description", text: "Description" },
      { id: "username", value: "username", text: "Username" },
      { id: "other", value: "other", text: "Other" },
    ],
  },
  {
    parent: "options-ama",
    value: "report-user",
    title: "Report User",
    isLoading: false,
    loadingText: "Sending report...",
    buttonText: "Send report",
    selectLabel: "What do you want to report?",
    selectID: "report-subject",
    inputDescription: "Please enter a reason:",
    inputPlaceholder: "Enter a reason...",
    inputName: "report-user",
  },
  {
    parent: "options-qa",
    value: "report-question",
    title: "Report Question",
    isLoading: false,
    loadingText: "Sending report...",
    buttonText: "Send report",
    selectLabel: "What do you want to report?",
    selectID: "report-subject",
    inputDescription: "Please enter a reason:",
    inputPlaceholder: "Enter a reason...",
    inputName: "report-question",
  },
  {
    parent: "options-qa",
    value: "report-answer",
    title: "Report Answer",
    isLoading: false,
    loadingText: "Sending report...",
    buttonText: "Send report",
    selectLabel: "What do you want to report?",
    selectID: "report-subject",
    inputDescription: "Please enter a reason:",
    inputPlaceholder: "Enter a reason...",
    inputName: "report-answer",
  },
];
